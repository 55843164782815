import React from 'react';
import {format} from "date-fns";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {ContactModel} from "../../API/Models";

type Props = {
    details: any;
    message: any;
    contact: ContactModel
    advocate: any;
    created_at: Date;
}

export default function OutgoingMessage({details, message, contact, advocate, created_at}: Props) {
    const classes = useStyles();

    const timestamp = format(new Date(created_at), "h:mm a");
    const advocateByline = advocate ? `Created by ${advocate.first_name} ${advocate.last_name}` : '';
    let text = message.body.message;
    if (message.body.p2p_message) {
        text = message.body.p2p_message;
    }
    if (details && details.message) {
        text = details.message;
    }
    text = text.replace('%recipient.first_name%', contact ? contact.firstName : '');
    text = text.replace('%recipient.last_name%', contact ? contact.lastName : '');
    text = text.replace('%advocate.first_name%', advocate ? advocate.first_name : '');
    text = text.replace('%advocate.last_name%', advocate ? advocate.last_name : '');

    return (
        <div className={classes.container}>
            <p title={`${timestamp}`} className={classes.byline}>{advocateByline}</p>
            {message.body.image ?
                <div className={classes.imageWrapper}>
                    <a href={message.body.image} target={"_blank"} rel={"noreferrer"}>
                        <img src={message.body.image} className={classes.image} alt={"Media sent with message"}/>
                    </a>
                </div>
                : null}
            <div className={classes.messageBox}>
                <div className={classes.flex}/>
                <p className={classes.messageText}>{text}</p>
            </div>
            <p title={`${timestamp}`} className={classes.details}>{timestamp}</p>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            alignItems: 'flex-end',
        },
        flex: {
            flex: 1,
        },
        messageBox: {
            flex: 1,
            display: "flex"
        },
        imageWrapper: {
            flex: 1,
            alignItems: 'flex-end',
            textAlign: "right"
        },
        image: {
            maxHeight: 100
        },
        messageText: {
            color: 'white',
            textAlign: "right",
            alignItems: 'flex-end',
            justifyContent: 'center',
            backgroundColor: '#0980fd',
            padding: 10,
            margin: 0,
            borderRadius: 12,
            maxWidth: 600
        },
        byline: {
            fontSize: 10,
            color: '#c0c0c0',
            margin: "0 10px 0 0",
            textAlign: "right",
            alignItems: 'flex-end',
            justifyContent: 'center',
        },
        details: {
            fontSize: 10,
            color: '#c0c0c0',
            margin: "0 10px 10px 0",
            textAlign: "right",
            alignItems: 'flex-end',
            justifyContent: 'center',
        }
    }
});
