import React from 'react';
import {format} from "date-fns";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';

type Props = {
    message: any;
    details: any;
    created_at: Date;
}

export default function Clicked({message, details, created_at}: Props) {
    const classes = useStyles();

    if(created_at) {
        const timestamp = format(new Date(created_at), "h:mm a");

        return (
            <div className={classes.container}>
                {/*<p style={styles.details}>{message.title}</p>*/}
                <p className={classes.details}>
                    <EmailIcon style={{fontSize:14, marginRight: 8}} /> {message.title} - {timestamp}
                </p>
                <p className={classes.details}>
                    Clicked on a link -&nbsp;<a href={details.url} target={"_blank"} rel={"noreferrer"}>{details.url}</a>
                </p>
            </div>
        );
    }else{
        return null;
    }
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            alignItems: 'flex-end',
            justifyContent: 'center',
        },
        details: {
            fontSize: 12,
            color: '#a0a0a0',
            marginRight: 15,
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
            overflow: "hidden"
        }
    }
});
