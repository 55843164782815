import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Theme, TextField, Button} from "@mui/material";
import SmsIcon from '@mui/icons-material/Sms';
import {ContactModel, MessageModel} from "../../API/Models";
import {useMyProfile} from "../../API/ProfileHooks";
import {useCreateContactAction} from "../../API/ActionHooks";
import Loading from "../Loading";
import {useSnackbar} from "notistack";
import AttachmentImage from "../AttachmentImage";
import AttachmentLink from "../AttachmentLink";


type Props = {
    contact: ContactModel;
    message: MessageModel;
    onShared: () => void;
}

export default function ContactNewTextMessage({contact, message, onShared}: Props) {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [busy, setBusy] = useState(false);
    const [text, setText] = useState('');
    const {data, isLoading} = useMyProfile();
    const [createContactAction] = useCreateContactAction();

    useEffect(() => {
        // console.log('message', message);
        if (message) {
            let intro = "";
            if (message.intro_text && message.intro_text.length > 0) {
                intro = message.intro_text+'\n';
            }
            let stop = "";
            if (message.stop_text && message.stop_text.length > 0) {
                stop = '\n'+message.stop_text;
            }
            if (message.p2p_message) {
                let replacedText = `${intro}${message.p2p_message}${stop}`;
                replacedText = replacedText.replace('%recipient.first_name%', contact ? contact.firstName : '');
                replacedText = replacedText.replace('%recipient.last_name%', contact ? contact.lastName : '');
                replacedText = replacedText.replace('%advocate.first_name%', data ? data.firstName : '');
                replacedText = replacedText.replace('%advocate.last_name%', data ? data.lastName : '');
                setText(replacedText);
            } else {
                let replacedText = `${intro}${message.message}${stop}`;
                replacedText = replacedText.replace('%recipient.first_name%', contact ? contact.firstName : '');
                replacedText = replacedText.replace('%recipient.last_name%', contact ? contact.lastName : '');
                replacedText = replacedText.replace('%advocate.first_name%', data ? data.firstName : '');
                replacedText = replacedText.replace('%advocate.last_name%', data ? data.lastName : '');
                setText(replacedText);
            }
        }
    }, [message, contact, data]);

    const handleSend = async () => {
        setBusy(true);
        let i = null;
        let m = message.p2p_message;
        let u = false;
        if (message.message === text) {
            m = message.message;
            u = false;
        }
        if (message.message !== text && message.p2p_message !== text) {
            m = text;
            u = true;
        }
        if (message.attachment.type === 'photo') {
            i = message.photos.length > 0 ? message.photos[0].path : null;
        }
        if (['message', 'form', 'page', 'event', 'url'].indexOf(message.attachment.type) >= 0) {
            m += ' ' + message.attachment.url;
        }
        const vars = {
            contact_id: contact.id.toString(),
            message_id: message.messageId,
            action: 'share',
            share_type: 'text',
            message: m,
            image: i,
            unique: u
        }
        const result = await createContactAction(vars);
        if (result) {
            onShared();
            enqueueSnackbar(`Peer Message Sent`, {variant: 'success'});
        } else {
            // failed to send what to do?
            enqueueSnackbar(`Peer Message Send Failed`, {variant: 'error'});
        }
        setBusy(false);
    }

    const Attachment = () => {
        if (message.attachment && message.attachment.type) {
            switch (message.attachment.type) {
                case "message":
                case "form":
                case "page":
                case "url":
                    return <AttachmentLink message={message} offset={20}/>;
                case "polling":
                    return null;
                case "photo":
                default:
                    return <AttachmentImage message={message} offset={20}/>;
            }
        } else {
            return null;
        }
    }

    if (isLoading) {
        return <Loading/>
    }

    return (
        <div className={classes.container}>
            {Attachment()}
            <TextField focused={true}
                       value={text}
                       multiline={true} rows={3}
                       placeholder={`Message to ${contact.firstName} ${contact.lastName}`}
                       onChange={(val) => setText(val.target.value)}
                       disabled={busy}
            />
            <div className={classes.sendButtonArea}>
                <div style={{flex: 1}}/>
                <Button onClick={handleSend}
                        disabled={busy}
                        startIcon={<SmsIcon/>}
                        className={classes.sendButton}>
                    Send Message
                </Button>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            paddingLeft: 10,
            paddingRight: 10,
            flex: 1,
            display: "flex",
            flexDirection: "column"
        },
        sendButtonArea: {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end"
        },
        sendButton: {
            fontWeight: "bold",
            fontSize: 16,
            margin: "5px 0 10px 0",
        }
    }
});
