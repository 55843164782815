import React, {ReactElement} from 'react';
import { BrowserRouter, Route,  Routes } from "react-router-dom";
import './App.css';
import AdapterDateFns from '@mui/lab/AdapterDateFns'; // Depending on the library you picked
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {IntlProvider} from "react-intl";
import messages from "./intl/en-US.json";
import Home from "./Pages/Home";
import DNF from "./DNF";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import {ReactQueryDevtools} from "react-query-devtools";
import Explore from "./Pages/Explore";
import Message from "./Pages/Message";
import Contacts from "./Pages/Contacts";
import Settings from "./Pages/Settings";
import Inbox from "./Pages/Inbox";
import {GroupProvider} from "./utils/GroupContext";
import Groups from "./Pages/Groups";
import Campaign from "./Pages/Campaign";

interface Props {
    c?: any;
}

const App = (props: Props): ReactElement => {
    return (
            <IntlProvider locale={'en-US'} messages={messages}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <GroupProvider>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Home/>} />
                                <Route path="/Signup" element={<Signup/>} />
                                <Route path="/Signup/:groupCode" element={<Signup/>} />
                                <Route path="/Login/*" element={<Login/>} />
                                {/*<Route path="/Login/:groupCode" element={<Login/>} />*/}
                                {/*<Route path="/ForgotPassword" element={<Login/>} />*/}
                                {/*<Route path="/ResetPassword" element={<Login/>} />*/}
                                <Route path="/App/Groups" element={<Groups/>} />
                                {/*<Route path="/App/:groupCode/Home" element={<Home/>} />*/}
                                <Route path="/App/:groupCode/Home" element={<Home/>} />
                                <Route path="/App/:groupCode/Home/:orgId" element={<Campaign/>} />
                                <Route path="/App/:groupCode/Explore" element={<Explore/>} />
                                <Route path="/App/:groupCode/Message/:messageId" element={<Message/>} />
                                <Route path="/App/:groupCode/Contacts" element={<Contacts/>} />
                                <Route path="/App/:groupCode/Inbox" element={<Inbox/>} />
                                <Route path="/App/:groupCode/Settings" element={<Settings/>} />
                                <Route  path="*" element={<DNF/>} />
                            </Routes>
                        </BrowserRouter>
                    </GroupProvider>
                    <ReactQueryDevtools initialIsOpen />
                </LocalizationProvider>
            </IntlProvider>
    );
};

export default App;
