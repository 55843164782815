import React, {ReactElement, useContext} from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
// import MessageIcon from '@mui/icons-material/Message';
import ContactsIcon from '@mui/icons-material/Contacts';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EditIcon from '@mui/icons-material/Edit';
import InboxIcon from '@mui/icons-material/Inbox';
import SearchIcon from '@mui/icons-material/Search';
import {NavLink, useNavigate} from "react-router-dom";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Cookie from "js-cookie";
import {GroupContext} from "../utils/GroupContext";
import AppStoreButtons from "./AppStoreButtons";
import InboxCount from "./InboxCount";

interface Props {
    width: number;
    variant?: 'permanent' | 'persistent' | 'temporary';
    open?: boolean;
    onClose?: any;
    classes?: any;
    ModalProps?: any;
}

const MenuDrawer = (props: Props): ReactElement => {
    const classes = useStyles();
    const navigate = useNavigate()
    const groupContext = useContext(GroupContext);
    const userToken = Cookie.get("token");

    const handleLogout = () => {
        Cookie.remove("token");
        navigate('/Login');
    }

    if (userToken) {
        return (
            <Drawer classes={props.classes}
                    style={{background: groupContext?.group.colors.background,}}
                    ModalProps={props.ModalProps}
                    PaperProps={{style: {width: props.width}}}
                    variant="permanent" {...props}>
                <List disablePadding>
                    <ListItem key={'nav-home'} className={classes.logoBox}
                              style={{backgroundImage: `linear-gradient(0deg, ${groupContext?.group.colors.primary}, ${groupContext?.group.colors.primary_shade})`}}>
                        <NavLink to={`/App/${groupContext?.group.group_code}/Home`} className={classes.logoWrapper}>
                            <img className={classes.logo} src={groupContext?.group.logo}
                                 alt={groupContext?.group.name}/>
                        </NavLink>
                    </ListItem>

                    <Divider className={classes.divider}/>
                    <ListItem key={'nav-messages'} button className={'item'}>
                        <NavLink to={`/App/${groupContext?.group.group_code}/Explore`}
                                 className={({isActive})=>`${classes.listItemLink} ${isActive?classes.activeItem:''}`}>
                            <ListItemIcon className={classes.listItemIcon}><SearchIcon/></ListItemIcon>
                            <ListItemText className={'itemText'}>Explore</ListItemText>
                        </NavLink>
                    </ListItem>
                    <ListItem key={'nav-campaigns'} button className={'item'}>
                        <NavLink to={`/App/${groupContext?.group.group_code}/Home`}
                                 className={({isActive})=>`${classes.listItemLink} ${isActive?classes.activeItem:''}`}>
                            <ListItemIcon className={classes.listItemIcon}><HomeIcon/></ListItemIcon>
                            <ListItemText className={'itemText'}>Home</ListItemText>
                        </NavLink>
                    </ListItem>
                    <ListItem key={'nav-contacts'} button className={'item'}>
                        <NavLink to={`/App/${groupContext?.group.group_code}/Contacts`}
                                 className={({isActive})=>`${classes.listItemLink} ${isActive?classes.activeItem:''}`}>
                            <ListItemIcon className={classes.listItemIcon}><ContactsIcon/></ListItemIcon>
                            <ListItemText className={'itemText'}>Contacts</ListItemText>
                        </NavLink>
                    </ListItem>
                    <ListItem key={'nav-notifications'} button className={'item'}>
                        <NavLink to={`/App/${groupContext?.group.group_code}/Inbox`}
                                 className={({isActive})=>`${classes.listItemLink} ${isActive?classes.activeItem:''}`}>
                            <ListItemIcon className={classes.listItemIcon}><InboxIcon/></ListItemIcon>
                            <ListItemText className={'itemText'}>Inbox <InboxCount /></ListItemText>
                        </NavLink>
                    </ListItem>
                    <ListItem key={'nav-settings'} button className={'item'}>
                        <NavLink to={`/App/${groupContext?.group.group_code}/Settings`}
                                 className={({isActive})=>`${classes.listItemLink} ${isActive?classes.activeItem:''}`}>
                            <ListItemIcon className={classes.listItemIcon}><SettingsIcon/></ListItemIcon>
                            <ListItemText className={'itemText'}>Settings</ListItemText>
                        </NavLink>
                    </ListItem>
                    <ListItem key={'nav-logout'} button className={'item'} onClick={handleLogout}>
                        <div className={classes.listItemLink}>
                            <ListItemIcon className={classes.listItemIcon}><ExitToAppIcon/></ListItemIcon>
                            <ListItemText className={'itemText'}>Logout</ListItemText>
                        </div>
                    </ListItem>
                </List>
                <AppStoreButtons />
                <div className={classes.poweredBy}>
                    <p>v{process.env.REACT_APP_VERSION}</p>
                    <img src={'/assets/PoweredBy.png'} width={120} alt={"Powered By Buzz360"}/>
                </div>
            </Drawer>
        );
    } else {
        return (
            <Drawer classes={props.classes} ModalProps={props.ModalProps} PaperProps={{style: {width: props.width}}}
                    variant="permanent" {...props}>
                <List disablePadding>
                    <ListItem className={classes.iconBox}>
                        <NavLink to={'/'} className={classes.iconSquircle}>
                            <img className={classes.icon} src={groupContext?.group.icon}
                                 alt={groupContext?.group.name}/>
                        </NavLink>
                    </ListItem>

                    <Divider className={'divider'}/>
                    <ListItem key={'nav-signup'} button className={'item'}>
                        <NavLink to={'/Signup'}
                                 className={({isActive})=>`${classes.listItemLink} ${isActive?classes.activeItem:''}`}>
                            <ListItemIcon className={classes.listItemIcon}><EditIcon/></ListItemIcon>
                            <ListItemText className={'itemText'}>Signup</ListItemText>
                        </NavLink>
                    </ListItem>
                    <ListItem key={'nav-login'} button className={'item'}>
                        <NavLink to={'/Login'}
                                 className={({isActive})=>`${classes.listItemLink} ${isActive?classes.activeItem:''}`}>
                            <ListItemIcon className={classes.listItemIcon}><LockOpenIcon/></ListItemIcon>
                            <ListItemText className={'itemText'}>Login</ListItemText>
                        </NavLink>
                    </ListItem>
                </List>
            </Drawer>
        )
    }
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        iconBox: {
            width: "100%",
            height: 130,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        iconSquircle: {
            overflow: "hidden",
            width: 85,
            height: 85,
            borderRadius: 15,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        icon: {
            width: 80,
            height: 80,
            borderRadius: 12,
        },
        logoBox: {
            width: "100%",
            height: 130,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        logoWrapper: {},
        logo: {
            width: 160,
            margin: "auto",
        },
        listItem: {
            width: "100%"
        },
        activeItem: {
            width: "100%",
            margin: "-8px -16px",
            padding: "8px 16px 8px 13px",
            borderLeft: "3px solid #333",
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            fontWeight: "bold"
        },
        listItemLink: {
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: "#333",
            fontWeight: "bold"
        },
        listItemIcon: {
            color: "#333"
        },
        divider: {
            marginBottom: 30
        },
        poweredBy: {
            width: "100%",
            // backgroundColor: "rgba(0,0,0,.2)",
            backgroundImage: `linear-gradient(180deg, transparent, #ddd)`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 50,
            padding: "20px 0 50px 0",
            position: "absolute",
            bottom: 0,
            flexDirection: "column",
            color: theme.palette.grey.A400,
            fontSize: 12
        }
    }
});

export default MenuDrawer;
