import React, {ReactElement, useEffect, useState} from 'react';
import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useMessageP2PContacts} from "../API/ContactHooks";
import {ContactModel, MessageModel} from "../API/Models";
import ContactActionsList from "./ContactActionsList";
import MessageP2PAssign from "./MessageP2PAssign";

interface Props {
    message: MessageModel;
}

const MessageP2PContacts = ({message}: Props): ReactElement => {
    const classes = useStyles();
    const [P2PContacts, setP2PContacts] = useState<ContactModel[]>([]);
    const messageP2PContacts = useMessageP2PContacts(message.messageId);

    useEffect(()=>{
        if(messageP2PContacts.data){
            const p2cs = messageP2PContacts.data.contacts.map(c=>({...c, isP2P: true}));
            setP2PContacts(p2cs);
        }else{
            setP2PContacts([]);
        }
    },[messageP2PContacts.data, setP2PContacts])

    if(P2PContacts && messageP2PContacts.data) {
        if (P2PContacts.length > 0) {
            return (
                <div className={classes.container}>
                    <h3>{P2PContacts.length} Assigned Peers
                        ({messageP2PContacts.data.completed} sent)</h3>
                    <div>
                        <ContactActionsList contacts={P2PContacts} message={message}/>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={classes.container}>
                    {messageP2PContacts.data ?
                        <h3>Message Peers ({messageP2PContacts.data.completed} sent)</h3>
                        :
                        <h3>Message Peers</h3>
                    }
                    <div>
                        <MessageP2PAssign message={message} />
                    </div>
                </div>
            )
        }
    } else {
        return <div></div>
    }
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {}
    }
});

export default MessageP2PContacts;
