import React, {ReactElement, useEffect, useState} from 'react';
import {
    Theme,
    DialogTitle,
    IconButton,
    Dialog,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CancelIcon from "@mui/icons-material/Cancel";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {useGoogleSDK} from "../utils/googleSDK";
import {GoogleContactGroup, mapGoogleContacts} from "../utils/googleModels";
import {useSnackbar} from "notistack";
import {useUploadContacts} from "../API/ContactHooks";

interface Props {
    open: boolean;
    onClose: () => void;
}

const GoogleContactsDialog = ({open, onClose}: Props): ReactElement => {
    const classes = useStyles();
    const [group, setGroup] = useState('contactGroups/all');
    const [busy, setBusy] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const {signIn, googleContactGroups, signedIn, init, fetchContactGroup, signOut} = useGoogleSDK();
    const [uploadContacts] = useUploadContacts();

    // useEffect(() => {
    //     console.log(googleContacts);
    // }, [googleContacts])
    //
    // useEffect(() => {
    //     console.log(googleContactGroups);
    // }, [googleContactGroups])

    useEffect(() => {
        if(open && !signedIn){
            init();
        }
    }, [open, init, signedIn])

    const handleConnect = () => {
        signIn();
    }

    const handleDisconnect = () => {
        signOut();
    }

    const handleGroupChange = (event: any) => {
        console.log(event.target.value);
        setGroup(event.target.value);
    }

    const handleImport = async () =>{
        setBusy(true);
        try {
            const gc = await fetchContactGroup(group);
            const mappedContacts = mapGoogleContacts(gc);
            console.log(mappedContacts);
            await uploadContacts(mappedContacts);
            setBusy(false);
            enqueueSnackbar(`${mappedContacts.length} Contacts Imported`, {variant: "success"});
            onClose();
        }catch(err){
            console.error(err);
            setBusy(false);
            enqueueSnackbar("Import Failed", {variant: "error"});
        }
    }

    return (
        <Dialog onClose={onClose} open={open} className={classes.dialog}>
            <DialogTitle className={classes.title}>
                <div>Google Contacts Sync</div>
                <IconButton onClick={onClose} size="large">
                    <CancelIcon fontSize={"large"}/>
                </IconButton>
            </DialogTitle>
            <div className={classes.container}>
                <div>
                    {signedIn ?
                        <div className={classes.button} onClick={handleDisconnect}>
                            <div className={"buttonIcon disabled"}></div>
                            <div>Sign Out of Google</div>
                        </div>
                        :
                        <div className={classes.button} onClick={handleConnect}>
                            <div className={"buttonIcon"}></div>
                            <div>Sign in with Google</div>
                        </div>
                    }
                </div>
                <div className={classes.form}>
                    <div>
                        <FormControl variant="outlined" className={classes.formControl} disabled={!signedIn}>
                            <InputLabel>Google Contacts Group</InputLabel>
                            <Select
                                value={group}
                                onChange={handleGroupChange}
                                label="Google Contacts Group"
                            >
                                <MenuItem value="" disabled={true}>
                                    <em>Choose a Contact Group</em>
                                </MenuItem>
                                {googleContactGroups.map((g: GoogleContactGroup) => {
                                    if ((g.memberCount && g.memberCount > 0) || g.resourceName==='contactGroups/all') {
                                        return (
                                            <MenuItem key={g.resourceName} value={g.resourceName}>{g.formattedName} ({g.memberCount || 0})</MenuItem>
                                        )
                                    }else{
                                        return null;
                                    }
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <Button variant={"contained"}
                                color={"primary"}
                                className={classes.importButton}
                                onClick={handleImport}
                                startIcon={<CloudDownloadIcon/>}
                                disabled={!signedIn || !group || busy}
                        >
                            Import Contacts from Google
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        dialog: {},
        container: {
            width: 300,
            maxWidth: "100%",
            height: 200,
            padding: 30,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        },
        form:{
            marginTop: 30,
        },
        formControl: {
            width: "100%",
        },
        importButton:{
            marginTop: 10,
            marginBottom: 30,
            padding: 10
        },
        title: {
            padding: "0 0 0 15px",
            "& h2": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }
        },
        button: {
            backgroundColor: "#fff",
            boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.3)",
            overflow: "hidden",
            display: "flex",
            alignItems: "center !important",
            padding: "0 20px 0 0",
            cursor: "pointer",
            fontFamily: "Roboto",
            fontWeight: "bold",
            fontSize: "14px !important",
            color: "#8d8d8b",
            lineHeight: 1.5,


            "& .buttonIcon":{
                width: 45,
                height: 45,
                backgroundImage: "url(/icons/btn_google_light_normal_ios.svg)",
                backgroundSize: "52px",
                backgroundPosition: "-3px -3px",
                marginRight: 10,
                "&.disabled":{
                    backgroundImage: "url(/icons/btn_google_light_disabled_ios.svg)",
                }
            },

            "& img": {
                marginRight: 10,
                border: 0,
                padding: 0
            },
            "&:hover": {
                backgroundColor: "#eee",
                "& .buttonIcon": {
                    backgroundImage: "url(/icons/btn_google_light_pressed_ios.svg)",
                }
            }
        },
    }
});

export default GoogleContactsDialog;
