import React, {ReactElement, useContext} from 'react';
import { Hidden, IconButton } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '@mui/icons-material/Menu';
import MenuDrawer from "./MenuDrawer";
import {GroupContext} from "../utils/GroupContext";
import Header from "./Header";

interface Props {
    children: React.ReactElement;
}

const drawerWidth = 200;

const MainWrapper = ({children}: Props): ReactElement => {
    const classes = useStyles();
    const groupContext = useContext(GroupContext);
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <div className={classes.container} style={{background: groupContext?.group.colors.background,}}>
            <nav className={classes.drawer}>
                <Hidden smUp implementation="js">
                    <MenuDrawer
                        width={drawerWidth}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{paper: classes.mobileDrawerPaper,}}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    />
                </Hidden>
                <Hidden smDown implementation="css">
                    <MenuDrawer
                        width={drawerWidth}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        variant="permanent"
                        open
                    />
                </Hidden>
            </nav>
            <Hidden smUp>
                <div className={classes.menuButton}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                        size="large">
                        <MenuIcon className={classes.menuIcon} />
                    </IconButton>
                </div>
            </Hidden>
            <main className={classes.main}>
                <Header />
                <div className={classes.content}>
                    {children}
                </div>
            </main>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: "flex",
            minHeight: "100%",
        },
        drawer:{
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        drawerPaper:{
            width: drawerWidth,
            backgroundColor: 'transparent',
        },
        mobileDrawerPaper:{
            width: drawerWidth,
        },
        menuButton: {
            position: "absolute",
            top: 5,
            left: 5,
            zIndex: 100
        },
        menuIcon:{

        },
        main:{
            marginTop: 130,
            minHeight: "100vh",
            maxWidth: "100%",
            flexGrow: 1,
            boxSizing: "border-box",
        },
        content:{
            padding: theme.spacing(3),
        }
    }
});

export default MainWrapper;
