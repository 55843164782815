import React, {useEffect, useState} from 'react';
import Cookie from "js-cookie"
import {useGetGroup} from "../API/GroupHooks";
import {GroupModel} from "../API/Models";

export const defaultGroup: GroupModel = {
    group_code: '',
    name: 'TeamUpWith',
    logo: '/assets/logo.png',
    icon: '/assets/icon.png',
    colors: {
        primary: "#1C4BAB",
        primary_shade: "#282E7B",
        accent: "#E4AA39",
        background: "#EFEFEF",
        background_text: "#333333",
        container: '#ffffff',
        container_shade: '#efefef',
        body: '#111111',
        title: '#000000',
        header: '#616770',
        subheader: '#80858c',
        muted: '#686d76',
        track: '#efefef',
        disabled:  '#d0d0d0',
        tint: "#1C4BAB",
    }
}

interface IGroupContext {
    group: GroupModel;
    setGroup: (group: GroupModel) => void;
}

interface GroupProviderOptions {
    children: React.ReactElement;
}

export const GroupContext = React.createContext<IGroupContext | null>(null);

export const GroupProvider = ({children}: GroupProviderOptions) => {
    const [group, setGroup] = useState<GroupModel>(defaultGroup);
    const [groupCode, setGroupCode] = useState<string>(group.group_code);
    const {data} = useGetGroup(groupCode);

    useEffect(() => {
        try {
            const c_group = Cookie.get("group");
            if(c_group) {
                setGroup(JSON.parse(c_group));
            }
            const c_group_code = Cookie.get("group_code");
            if(c_group_code) {
                setGroupCode(c_group_code);
            }
        } catch (e) {
            console.log('group cookie not found', e);
        }
    }, []);

    useEffect(() => {
        if(data){
            // console.log('loading group data', data);
            setGroup({...data, colors: {...defaultGroup.colors, ...data.colors}});
        }
    }, [data]);


    useEffect(() => {
        if(group){
            // console.log('loading group', group);
        }else{
            // console.log('loading default group');
        }
    }, [group]);

    const setUserGroup = (group: any) => {
        setGroup({...group, colors: {...defaultGroup.colors, ...group.colors}});
        if(group){
            Cookie.set("group_code", group.group_code, {expires: 30, sameSite: 'strict'});
            Cookie.set("group", group, {expires: 30, sameSite: 'strict'});
        }else{
            Cookie.remove("group_code");
            Cookie.remove("group");
        }
    }

    return (
        <GroupContext.Provider value={{group: group, setGroup: setUserGroup}}>
            {children}
        </GroupContext.Provider>
    );
}
