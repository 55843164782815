import React, {ReactElement, useState} from 'react';
import {Button, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {MessageModel} from "../API/Models";
import {useAssignP2PContacts} from "../API/ContactHooks";

interface Props {
    message: MessageModel;
}

const MessageP2PAssign = ({message}: Props): ReactElement => {
    const classes = useStyles();
    const [busy, setBusy] = useState(false);
    const [assignContacts] = useAssignP2PContacts(message);

    const handleAssign = async () => {
        setBusy(true);
        await assignContacts();
        setBusy(false);
    }

    return (
        <div>
            <p>Help us also reach people that you may not know.</p>
            <div>
                <Button variant={"contained"}
                        color={"primary"}
                        className={classes.actionButton}
                        onClick={handleAssign}
                        disabled={busy}
                >
                    Assign Me 5 Contacts
                </Button>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {},
        actionButton:{
            marginTop: 10,
            marginBottom: 30,
            padding: 10
        },
    }
});

export default MessageP2PAssign;
