import React, {ReactElement, useContext, useEffect, useState} from 'react';
import { Theme, TextField, InputAdornment, Button, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useNavigate} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {GroupContext} from "../utils/GroupContext";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import CheckIcon from '@mui/icons-material/Check';
import {useGetGroup} from "../API/GroupHooks";
import Cookie from "js-cookie";
import InputPhone from "../Components/InputPhone";

const defaultUser = {
    group_code: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    postal_code: '',
    password: ''
}

interface Props {
    c?: any;
}

const SignUpContainer = (props: Props): ReactElement => {
    const classes = useStyles();
    const navigate = useNavigate();
    const groupContext = useContext(GroupContext);
    defaultUser.group_code = groupContext?.group.group_code||'';
    const [error, setError] = useState('');
    const [state, setState] = useState(defaultUser);
    const [busy, setBusy] = useState(false);
    const [valid, setValid] = useState(false);
    const {data} = useGetGroup(state.group_code||'');

    useEffect(()=>{
        console.log('inputValidation', state)
        const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!state.group_code || state.group_code.length<2) {
            console.log('inputValidation', 'bad group_code');
            setValid(false);
        }else if (state.first_name.length<2) {
            console.log('inputValidation', 'bad first_name');
            setValid(false);
        }else if (state.last_name.length<2) {
            console.log('inputValidation', 'bad last_name');
            setValid(false);
        }else if (state.phone.length<10) {
            console.log('inputValidation', 'bad phone');
            setValid(false);
        }else if (state.email.length<5) {
            console.log('inputValidation', 'bad email length');
            setValid(false);
        }else if (!emailReg.test(state.email.toLowerCase())) {
            console.log('inputValidation', 'bad email regex');
            setValid(false);
        }else if (state.password.length<6) {
            console.log('inputValidation', 'bad password');
            setValid(false);
        }else{
            setValid(true);
        }
    }, [state, setValid]);

    const handleCodeChange = (event: any) => {
        setState({...state, group_code: event.target.value.toUpperCase()});
    }

    const handleStateChange = async (property: string, value: string) => {
        await setState({...state, [property]: value});
    };

    const signup = async () => {
        try {
            setError('');
            await setBusy(true);
            const values = {
                username: state.email.toLowerCase(),
                password: state.password,
                attributes: {
                    given_name: state.first_name,
                    family_name: state.last_name,
                    email: state.email.toLowerCase(),
                    phone_number: state.phone,
                    "custom:postal_code": state.postal_code,
                    "custom:group_code": state.group_code,
                },
            }
            const result = await Auth.signUp(values);
            console.log('result', result);
            const user = await Auth.signIn(state.email.toLowerCase(), state.password);
            const token = user.signInUserSession.idToken.jwtToken;
            await Cookie.set("token", token, {expires: (1 / 24), sameSite: 'Lax'});
            await setBusy(false);
            navigate('/Home');
        } catch (err) {
            // @ts-ignore
            setError(err.message);
            await setBusy(false);
            console.log(err);
        }
    }

    return (
        <div className={classes.container}>
            <h2 className={classes.header} style={{color: groupContext?.group.colors.primary}}>
                Create an Account
            </h2>
            <TextField
                className={classes.textField}
                required
                id="outlined-group-code"
                label="Group Code"
                value={state.group_code || ''}
                disabled={busy}
                autoComplete={"off"}
                onChange={handleCodeChange}
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <LocalOfferIcon />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {(data && data.group_code) ?
                                <CheckIcon color={"action"} />
                                : <span></span>
                            }
                        </InputAdornment>
                    )
                }}
            />
            <TextField
                className={classes.textField}
                required
                id="first-name"
                label="First Name"
                value={state.first_name}
                autoFocus={true}
                autoComplete={"given-name"}
                disabled={busy}
                onChange={event => handleStateChange("first_name", event.target.value)}
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PersonIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.textField}
                required
                id="last-name"
                label="Last Name"
                value={state.last_name}
                disabled={busy}
                autoComplete={"family-name"}
                onChange={event => handleStateChange("last_name", event.target.value)}
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PersonIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.textField}
                required
                id="postal-code"
                label="Postal / Zip Code"
                value={state.postal_code}
                disabled={busy}
                autoComplete={"postal-code"}
                onChange={event => handleStateChange("postal_code", event.target.value)}
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <HomeIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <InputPhone
                onChange={(value:string) => handleStateChange("phone", value)}
                disabled={busy}
                className={classes.phoneField}
                required
                id="phone"
                label="Phone Number"
                value={state.phone}
                autoComplete={"phone-number"}
                variant={"outlined"}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PhoneIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.textField}
                required
                id="email"
                label="Email Address"
                value={state.email}
                disabled={busy}
                autoComplete={"username"}
                onChange={event => handleStateChange("email", event.target.value)}
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <EmailIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.textField}
                id="password"
                label="Password"
                type="password"
                value={state.password}
                disabled={busy}
                onChange={event => handleStateChange("password", event.target.value)}
                autoComplete="new-password"
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <LockIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <Button variant="contained" color="primary" disabled={(busy || !valid)} onClick={signup}>
                {!busy
                    ? <span>Sign up</span>
                    : <CircularProgress color={"secondary"}/>
                }
            </Button>
            <div className={classes.error}>
                {error && error.length>0 ?
                    <p>{error}</p>
                    : null}
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            borderRadius: 10,
            maxWidth: 350,
            padding: 20,
            margin: "auto"
        },
        header:{
            marginTop: 0,
            marginBottom: 20
        },
        textField:{
            marginBottom: "10px !important"
        },
        phoneField:{
            flexDirection: "row-reverse",
            "& .MuiOutlinedInput-root": {
                marginRight: -40
            },
            marginBottom: 10,
        },
        error: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& p": {
                paddingTop: 10,
                color: "#cb2629"
            }
        }
    }
});

export default SignUpContainer;
