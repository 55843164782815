const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  "aws_project_region": process.env.REACT_APP_REGION,
  // "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  "aws_cognito_region": process.env.REACT_APP_REGION,
  "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOL_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  "oauth": {},
  aws: {
    Auth: {
      // identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    },
  },
  env: process.env.ENVIRONMENT,
};

export default config;
