import React, {ReactElement, useContext} from 'react';
import { Theme, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {NavLink} from "react-router-dom";
import {GroupContext} from "../utils/GroupContext";
import {useMyCampaigns} from "../API/CampaignHooks";
import Loading from "./Loading";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface Props {
    c?: any;
}

const CampaignsList = (props: Props): ReactElement => {
    const classes = useStyles();
    const groupContext = useContext(GroupContext);
    const {data, isLoading} = useMyCampaigns(groupContext?.group.group_code||'');

    if(isLoading){
        return <Loading />
    }

    return (
        <div className={classes.container}>
            {data ?
                <div className={classes.campaignStrip}>
                    {data.map(c => (
                        <Paper className={classes.paper} key={c.networkId}>
                            <NavLink
                                key={c.networkId}
                                to={`/App/${groupContext?.group.group_code}/Home/${c.networkId}`}
                                className={classes.campaignButton}
                            >
                                <img src={c.photos[0].path} className={classes.buttonImage} alt={c.name} />
                                <p className={classes.campaignLabel}>{c.name}</p>
                            </NavLink>
                        </Paper>
                    ))}
                    <Paper className={classes.paper}  style={{backgroundColor: "rgba(255,255,255,.5)", boxShadow: "0 0 1px rgba(0,0,0,.2)"}} key={'newCampaign'}>
                        <NavLink className={classes.campaignButton} to={`/App/${groupContext?.group.group_code}/Explore`}>
                            <AddCircleOutlineIcon fontSize={"large"} color={"primary"} />
                        </NavLink>
                    </Paper>
                </div>
                : null}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: "flex",
            flexDirection: "row",
        },
        campaignStrip:{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "flex-start"
        },
        paper:{
            margin: 5,
            backgroundColor: "white",
            overflow: "hidden",
        },
        campaignButton: {
            width: 150,
            height: 84,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 5,
            borderRadius: 4,
            border: "1px solid transparent",
            textDecoration: "none",
            cursor: "pointer",
            "& p": {
                margin: "4px 0",
                fontSize: 12,
                color: "#777"
            },
            "&:hover":{
                border: "1px solid rgba(0,0,0,.5)",
            }
        },
        buttonImage:{
            maxHeight: 64,
            maxWidth: "100%",
        },
        campaignLabel:{
            paddingBottom: 15,
            maxWidth: "90%",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        }
    }
});

export default CampaignsList;
