import React, {ReactElement, useContext} from 'react';
import {GroupContext} from "../utils/GroupContext";
import {useGroupMessages, useOrgMessages} from "../API/MessageHooks";
import {OrgModel, MessageModel} from "../API/Models";
import Loading from "./Loading";
import MessageCard from "./MessageCard";
import {Typography} from "@mui/material";
import { Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';
import MessageAction from "./MessageAction";
import Masonry from 'react-masonry-css'

interface Props {
    campaign?: OrgModel;
}

const MessagesList = ({campaign}: Props): ReactElement => {
    const classes = useStyles();
    const groupContext = useContext(GroupContext);
    const groupMessages = useGroupMessages(groupContext?.group.group_code || '');
    const orgMessages = useOrgMessages(campaign ? campaign.networkId : '');
    let data: MessageModel[] = [];
    if(campaign) {
        data = orgMessages.data || [];
    }else{
        data = groupMessages.data || [];
    }

    if(groupMessages.isLoading || orgMessages.isLoading){
        return <Loading />
    }

    const breakpointColumnsObj = {
        default: 4,
        1800: 3,
        1300: 2,
        960: 1,
    };

    return (
        <div className={classes.container}>
            {data && data.length > 0 ?
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className={classes.masonryGrid}
                    columnClassName={classes.masonryGridColumn}>
                    {data.map(m => (
                        <MessageCard
                            key={m.messageId}
                            message={m}
                            action={<MessageAction message={m} />}
                            style={{
                                marginTop: 10,
                                marginBottom: 5
                            }}
                        />
                    ))}
                </Masonry>
                :
                <Typography color={'textSecondary'} className={classes.empty}>
                    No Active Messages
                </Typography>
            }
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: "flex",
        },
        empty: {
            padding: theme.spacing(2),
        },
        masonryGrid:{
            display: 'flex',
            marginLeft: -10,
            width: "auto"
        },
        masonryGridColumn:{
            paddingLeft: 10,
            backgroundClip: "padding-box"
        },
        messageWrapper: {
            flex: 1,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "flex-start"
        },
        joinMessage:{
            fontSize: 12,
            color: "#c0c0c0"
        }
    }
});

export default MessagesList;
