
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import { Theme, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MainWrapper from "../Components/MainWrapper";
import {GroupContext} from "../utils/GroupContext";
import {useGroupCampaigns} from "../API/CampaignHooks";
import {NavLink} from "react-router-dom";
import {OrgSectionModel} from "../API/Models";
import Loading from "../Components/Loading";
import AuthWrapper from "../Components/AuthWrapper";

interface Props {
    c?: any;
}

const Explore = (props: Props): ReactElement => {
    const classes = useStyles();
    const groupContext = useContext(GroupContext);
    const [campaigns, setCampaigns] = useState<OrgSectionModel[]>([]);
    const {data, isLoading} = useGroupCampaigns(groupContext?.group.group_code || '');

    useEffect(()=>{
        if(data) {
            // console.log('data',data);
            const networkMap: any = {};

            for (const network of data) {
                let visibilityRegion = network.visibilityRegion || "NATIONAL";
                if (!networkMap[visibilityRegion]) {
                    networkMap[visibilityRegion] = {
                        title: visibilityRegion,
                        data: [],
                    };
                }
                networkMap[visibilityRegion].data.push(network);
            }
            // console.log('networkMap',networkMap);
            const networkList: OrgSectionModel[] = Object.values(networkMap);
            networkList.sort((a: OrgSectionModel, b: OrgSectionModel) => {
                if (a.title === "NATIONAL") {
                    return -1;
                }
                if (b.title === "NATIONAL") {
                    return 1;
                }
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            });
            setCampaigns(networkList);
        }else{
            setCampaigns([]);
        }
    }, [data]);


    if(isLoading){
        return <Loading />
    }

    return (
        <AuthWrapper>
            <MainWrapper>
                <div className={classes.container}>
                    {campaigns ?
                        <div>
                            {campaigns.map(ctop => (
                                <div key={ctop.title}>
                                    <h3>{ctop.title}</h3>
                                    <div className={classes.campaignStrip}>
                                        {ctop.data.map(c => (
                                            <Paper className={classes.paper} key={c.networkId}>
                                                <NavLink
                                                    key={c.networkId}
                                                    to={`/App/${groupContext?.group.group_code}/Home/${c.networkId}`}
                                                    className={classes.campaignButton}
                                                >
                                                    <img src={c.photos[0].path} className={classes.buttonImage} alt={c.name} />
                                                    <p>{c.name}</p>
                                                </NavLink>
                                            </Paper>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                        : null}
                </div>
            </MainWrapper>
        </AuthWrapper>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: "flex",
            flexDirection: "row",
        },
        campaignStrip:{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "flex-start"
        },
        paper:{
            margin: 5,
            backgroundColor: "white",
        },
        campaignButton: {
            width: 200,
            height: 100,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 5,
            borderRadius: 4,
            border: "1px solid transparent",
            textDecoration: "none",
            cursor: "pointer",
            "& p": {
                margin: "4px 0",
                fontSize: 12,
                color: "#777"
            },
            "&:hover":{
                border: "1px solid rgba(0,0,0,.5)",
            }
        },
        buttonImage:{
            maxHeight: 80,
            maxWidth: "100%",
        },
    }
});

export default Explore;
