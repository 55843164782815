import React, {ReactElement, useContext} from 'react';
import { Theme, Breadcrumbs, Link, Typography, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MainWrapper from "../Components/MainWrapper";
import {NavLink, useParams} from "react-router-dom";
import {GroupContext} from "../utils/GroupContext";
import {useGroupTheme} from "../API/useGroupTheme";
import {useGetCampaign} from "../API/CampaignHooks";
import MessagesList from "../Components/MessagesList";
import CampaignSubscription from "../Components/CampaignSubscription";
import TwoColumnWrapper from "../Components/TwoColumnWrapper";
import AuthWrapper from "../Components/AuthWrapper";

interface Props {
    c?: any;
}

const Campaign = (props: Props): ReactElement => {
    const classes = useStyles();
    const groupContext = useContext(GroupContext);
    const {groupCode, orgId} = useParams();
    useGroupTheme(groupCode);
    const group = groupContext?.group;
    const {data} = useGetCampaign(orgId || '');

    const sidebar = (
        <div>
            {data ?
                <div>
                    <h3>{data.name}</h3>
                    <p className={classes.orgDescription}>{data.description}</p>
                    <p><Link href={`http://${data.domain}`} target={'_blank'}>{data.domain}</Link>
                    </p>
                    <p>{data.contactPhone}</p>
                    <p><Link href={`mailto://${data.contactEmail}`}
                             target={'_blank'}>{data.contactEmail}</Link></p>
                    {data.locationRegion ?
                        <div>
                            <p>{data.locationAddress}</p>
                            <p>{data.locationCity}, {data.locationRegion} {data.locationPostal}</p>
                        </div>
                        :
                        null
                    }
                </div>
                :
                null
            }
        </div>
    )

    // console.log(data);

    return (
        <AuthWrapper>
            <MainWrapper>
                <div>
                    {data ?
                        <div className={classes.container}>
                            <div className={classes.topStrip}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <NavLink to={`/App/${group?.group_code}/Home`} className={classes.crumbLink}>
                                        Home
                                    </NavLink>
                                    <Typography color="textPrimary">{data.name}</Typography>
                                </Breadcrumbs>
                                <CampaignSubscription campaign={data}/>
                            </div>

                            <TwoColumnWrapper
                                sidebar={sidebar}
                                sidebarStyle={{
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                <Paper className={classes.main}>
                                    <img src={data.photos[0].path} className={classes.campaignLogo} alt={data.name}/>
                                </Paper>
                            </TwoColumnWrapper>

                            <h3>Messages</h3>

                            <MessagesList campaign={data}/>

                        </div>
                        : null}
                </div>
            </MainWrapper>
        </AuthWrapper>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {},
        topStrip: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        crumbLink: {
            color: "rgba(0,0,0,.54)",
            textDecoration: "none",
            "&:hover": {
                textDecoration: "underline"
            }
        },
        main:{
            flex: 1,
            display: "flex",
            padding: theme.spacing(2),
            alignItems: "center",
            justifyContent: "center"
        },
        campaignLogo: {
            maxHeight: 300,
            maxWidth: "100%",
        },
        orgDescription: {
            maxHeight: 305,
            overflow: "hidden",
        }
    }
});

export default Campaign;
