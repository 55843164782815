import React, {ReactElement} from 'react';
import { Theme, Paper } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

interface Props {
    children: any;
    mainStyle?: any;
    sidebar: any;
    sidebarStyle?: any;
}

const TwoColumnWrapper = ({children, sidebar, mainStyle, sidebarStyle}: Props): ReactElement => {
    const classes = useStyles();
    return (
        <div className={classes.mainSection}>
            <div className={classes.mainArea} style={mainStyle}>
                {children}
            </div>
            <Paper className={classes.sideBar} style={sidebarStyle}>
                {sidebar}
            </Paper>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {},
        mainSection: {
            display: "flex",
            alignItems: "flex-start",
            marginTop: theme.spacing(3),
            [theme.breakpoints.down(600)]: {
                flexDirection: "column"
            }
        },
        mainArea: {
            flex: 2,
            marginRight: theme.spacing(3),
            display: "flex",
            minHeight: 300,
            [theme.breakpoints.down(600)]: {
                flex: 1,
                width: "100%",
                marginRight: 0,
                marginBottom: theme.spacing(3),
            }
        },
        sideBar: {
            flex: 1,
            boxSizing: "border-box",
            minHeight: 300,
            display: "flex",
            alignItems: "flex-start",
            padding: theme.spacing(2),
            "& h3": {
                marginTop: 0,
            },
            [theme.breakpoints.down(600)]: {
                width: "100%",
                minHeight: "auto",
            }
        },
    };
});

export default TwoColumnWrapper;
