import React from 'react';
import { format } from "date-fns";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

type Props = {
    details: any;
    advocate: any;
    created_at: Date;
}

export default function IncomingMessage({details, created_at}: Props) {
    const classes = useStyles();

    const timestamp = format(new Date(created_at),"h:mm a");

    return (
        <div className={classes.container}>
            <div className={classes.messageBox}>
                <p className={classes.messageText}>{details.message}</p>
                <div className={classes.flex} />
            </div>
            <p className={classes.details}>{timestamp}</p>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            alignItems: 'flex-start',
        },
        flex:{
            flex: 1,
        },
        messageBox: {
            flex: 1,
            display: "flex"
        },
        messageText: {
            backgroundColor: '#e9e9eb',
            borderRadius: 12,
            padding: 10,
            color: '#000',
            margin: 0
        },
        details: {
            fontSize: 10,
            color: '#c0c0c0',
            margin: "0 0 10px 10px",
        }
    }
});
