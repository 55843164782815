import React, {ReactElement, useEffect, useState} from 'react';
import { Theme, ButtonBase, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Gravatar from 'react-gravatar';
import {useDropzone} from 'react-dropzone'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

enum AvatarVariant {
    round = "round",
    square = "square"
}

interface Props {
    email?: string;
    image: string;
    path: string;
    onChange: any;
    variant?: string;
}

const AvatarButton = (props: Props): ReactElement => {
    const classes = useStyles();
    const [state, setState] = useState({imageUrl: props.image, email: props.email, busy: false, file: null});
    const onDrop = async (files:any) => {
        // console.log(files);
        // setState({imageUrl: state.imageUrl, busy: true, file: files[0]});
        // console.log(formatFilename(files[0].name));
    };
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    const {ref, ...rootProps} = getRootProps()
    // const {refetch} = useGetSignedImageQuery()

    useEffect(() => {
        if (state.file) {
            console.log('file change detected');
            sendFile().then(r => {
            });
        }
    }, [state]);

    useEffect(() => {
        setState({imageUrl: props.image, email: props.email, busy: false, file: null})
    }, [props, setState]);

    const sendFile = async () => {
        console.log('sending file');
        // const response = await refetch({
        //     subFolder: props.path,
        //     fileName: formatFilename(state.file.name),
        //     fileType: state.file.type
        // });
        // const {signedRequest, url} = response.data.teamupwith_backend.s3.getSignedImage;
        // await uploadToS3(state.file, signedRequest);
        // console.log('file uploaded: ' + url);
        // setState({imageUrl: url, busy: false, file: state.file})
        // props.onChange(url);
    };

    if (!props.image && !props.email && !state.imageUrl) {
        setState({...state, imageUrl: "/images/logo_placeholder.png"});
    }

    return (
        <div ref={ref}>
            <ButtonBase className={clsx({
                [classes.image]: true,
                [classes.round]: props.variant !== AvatarVariant.square
            })} {...rootProps}>
                {isDragActive ?
                    <div className={classes.iconOverlay}>
                        <AddAPhotoIcon className={classes.icon} color={'primary'}/>
                    </div>
                    : null}
                {state.busy ?
                    <CircularProgress className={classes.spinner} color={'primary'}/>
                    :
                    <>
                        {state.imageUrl ?
                            <div className={classes.image} style={{
                                backgroundImage: `url(${state.imageUrl})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                border: 0
                            }} />
                            :
                            <Gravatar className={classes.img} email={state.email} default={'mp'} size={260}/>
                        }
                    </>
                }
                <input {...getInputProps()} accept={'image/*'}/>
            </ButtonBase>
        </div>
    );
};
const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {},
        hidden: {
            display: "none"
        },
        iconOverlay: {
            position: "absolute",
            width: '100%',
            height: "100%",
            backgroundColor: "rgba(255,255,255,.8)",
            border: "2px dashed silver",
            borderColor: theme.palette.primary.main,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        icon: {
            width: '40%',
            height: '40%',
            margin: '0 auto 8px auto',
            display: 'block',
            position: 'absolute',
        },
        spinner: {
            margin: '0 auto',
            display: 'block'
        },
        image: {
            width: 120,
            height: 120,
            backgroundColor: '#fff',
            border: '1px solid #efefef',
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
            maxWidth: "100%"
        },
        round: {
            borderRadius: '50%',
        },
        img: {
            margin: 'auto',
            display: 'block',
            width: '100%',
            height: '100% !important'
        },
    }
});
export default AvatarButton;
