import {useContext} from "react";
import {GroupContext} from "../utils/GroupContext";
import {useGetGroup} from "./GroupHooks";

export function useGroupTheme(group_code: string | undefined) {
    const groupContext = useContext(GroupContext);
    const {data} = useGetGroup(group_code);

    if(groupContext){
        if(data && groupContext.group && groupContext.group.group_code!==group_code){
            if(data){
                groupContext.setGroup(data);
            }
        }else{
            return groupContext.group;
        }
    }else{
        return null;
    }
}
