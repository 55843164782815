import React, {ReactElement, useContext, useState} from 'react';
import {
    Theme,
    Dialog,
    DialogTitle,
    IconButton,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CancelIcon from '@mui/icons-material/Cancel';
import {OrgModel} from "../API/Models";
import {useMyCampaigns} from "../API/CampaignHooks";
import {GroupContext} from "../utils/GroupContext";
import Loading from "./Loading";
import MailIcon from '@mui/icons-material/Mail';
import TextsmsIcon from '@mui/icons-material/Textsms';

interface Props {
    open: boolean;
    onClose: () => void;
}

const ContactCampaignDetail = ({open, onClose}: Props): ReactElement => {
    const classes = useStyles();
    const [campaignValue, setCampaignValue] = useState('')
    const [campaign, setCampaign] = useState<OrgModel>()
    const groupContext = useContext(GroupContext);
    const {data, isLoading} = useMyCampaigns(groupContext?.group.group_code || '');

    const handleCampaignChange = (event: any) => {
        setCampaignValue(event.target.value);
        data?.forEach(c => {
            if (c.networkId === event.target.value) {
                setCampaign(c);
                console.log(c);
            }
        })
    }

    const handleContactCampaign = (contactType: string, campaign: OrgModel) => {
        switch (contactType){
            case 'email':
                window.location.href = `mailto:${campaign.volunteerEmail}`;
                break;
            case 'sms':
                window.location.href = `sms:${campaign.volunteerPhone}`;
                break;
        }
    }

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle className={classes.title}>
                <div>Contact Campaign</div>
                <IconButton onClick={onClose} size="large">
                    <CancelIcon fontSize={"large"}/>
                </IconButton>
            </DialogTitle>
            <div className={classes.container}>
                <div className={classes.main}>
                    {data && !isLoading ?
                        <FormControl className={classes.formControl}>
                            <InputLabel>Campaign</InputLabel>
                            <Select
                                value={campaignValue}
                                onChange={handleCampaignChange}
                            >
                                {data.map(c => (
                                    <MenuItem value={c.networkId} key={c.networkId}>{c.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        :
                        <Loading/>
                    }
                </div>
                <div className={classes.actionStrip}>
                    {campaign && campaign.volunteerEmail ?
                        <Button
                            className={classes.button}
                            startIcon={<MailIcon/>}
                            onClick={()=>handleContactCampaign('email', campaign)}
                        >
                            Send Mail Message
                        </Button>
                        : null}
                    {campaign && campaign.volunteerPhone ?
                        <Button
                            className={classes.button}
                            startIcon={<TextsmsIcon/>}
                            onClick={()=>handleContactCampaign('sms', campaign)}
                        >
                            Send Text Message
                        </Button>
                        : null}
                </div>
            </div>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            width: 450,
            maxWidth: "100%"
        },
        form: {
            padding: 15
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 200,
        },
        title: {
            padding: "0 0 0 15px",
            "& h2": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }
        },
        main: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        actionStrip: {
            display: "flex",
            justifyContent: "space-between",
            padding: theme.spacing(3),
        },
        button: {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            borderRadius: 25,
            display: "flex",
            alignItems: "center",
            padding: "8px 16px",
            cursor: "pointer",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 12,
            lineHeight: 1.5,
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
            }
        },
    }
});

export default ContactCampaignDetail;
