import {useQuery, useMutation, queryCache} from "react-query";
import {OrgModel, OrgTeamModel} from "./Models";
import {useAuthClient} from "./useClient";

export function useMyCampaigns(group_code: string) {
    const client = useAuthClient();

    return useQuery<OrgModel[]>(['myCampaigns', group_code], async () => {
        try {
            if (group_code && client) {
                const response = await client.get(`/orgs/?group_code=${group_code}`);
                return response.data;
            } else {
                return [];
            }
        } catch (err) {
            console.error(err);
        }
    });
}

export function useGroupCampaigns(group_code: string) {
    const client = useAuthClient();

    return useQuery<OrgModel[]>(['groupCampaigns', group_code], async () => {
        try {
            if(client && group_code) {
                const response = await client.get(`/groups/${group_code}/orgs`);
                return response.data;
            }else{
                return [];
            }
        } catch (err) {
            console.error(err);
        }
    });
}

export function useGetCampaign(network_id: string) {
    const client = useAuthClient();

    return useQuery<OrgModel>(['orgMessage', network_id], async () => {
        try {
            if (network_id && client) {
                const response = await client.get(`/orgs/${network_id}`);
                return response.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error(err);
        }
    });
}

export function useJoinCampaign(network_id: string) {
    const client = useAuthClient();

    return useMutation( async () => {
        try {
            if (network_id && client) {
                const response = await client.post(`/orgs/${network_id}`);
                await queryCache.invalidateQueries('myCampaigns');
                await queryCache.invalidateQueries('orgMessages');
                return response;
            } else {
                return null;
            }
        } catch (err) {
            console.error(err);
        }
    });
}

export function useLeaveCampaign(network_id: string) {
    const client = useAuthClient();

    return useMutation( async () => {
        try {
            if (network_id && client) {
                const response = await client.delete(`/orgs/${network_id}`);
                await queryCache.invalidateQueries('myCampaigns');
                await queryCache.invalidateQueries('orgMessages');
                return response;
            } else {
                return null;
            }
        } catch (err) {
            console.error(err);
        }
    });
}

export function useJoinOrg(org: OrgModel | undefined) {
    const client = useAuthClient();

    return useMutation(async () => {
        try {
            if (org && client) {
                const response = await client.post(`/orgs/${org.networkId}`);
                await queryCache.invalidateQueries('myOrgs');
                await queryCache.invalidateQueries('orgMessages');
                await queryCache.invalidateQueries('groupMessages');
                return response.data.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error('OrgHooks.useJoinOrg', err);
        }
    });
}

export function useJoinOrgTeam(org: OrgModel | undefined) {
    const client = useAuthClient();

    return useMutation(async (team: OrgTeamModel) => {
        try {
            if (org && client) {
                const response = await client.post(`/orgs/${org.networkId}/team/${team.id}`);
                await queryCache.invalidateQueries('myOrgs');
                await queryCache.invalidateQueries('orgMessages');
                await queryCache.invalidateQueries('groupMessages');
                return response.data.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error('OrgHooks.useJoinOrgTeam', err);
        }
    });
}

export function useLeaveOrg(org: OrgModel | undefined) {
    const client = useAuthClient();

    return useMutation(async () => {
        try {
            if (org && client) {
                const response = await client.delete(`/orgs/${org.networkId}`);
                await queryCache.invalidateQueries('myOrgs');
                await queryCache.invalidateQueries('orgMessages');
                return response.data.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error('OrgHooks.useLeaveOrg', err);
        }
    });
}

export function useLeaveOrgTeam(org: OrgModel | undefined) {
    const client = useAuthClient();

    return useMutation(async (team: OrgTeamModel) => {
        try {
            if (org && client) {
                const response = await client.delete(`/orgs/${org.networkId}/team/${team.id}`);
                await queryCache.invalidateQueries('myOrgs');
                await queryCache.invalidateQueries('orgMessages');
                return response.data.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error('OrgHooks.useLeaveOrgTeam', err);
        }
    });
}
