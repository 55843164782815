import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {GroupContext} from "../utils/GroupContext";

interface Props {
    c?: any;
}

const MessageConfig = (props: Props): ReactElement => {
    const classes = useStyles();
    const groupContext = useContext(GroupContext);
    const [os, setOS] = useState('Mac');
    const [iLink, setILink] = useState('');

    useEffect(()=>{
        if (navigator.appVersion.indexOf("Win") !== -1) {
            setOS("Windows");
            setILink("https://support.microsoft.com/en-us/topic/send-and-receive-text-messages-from-your-pc-43189e43-8121-35f6-7930-db095bf938a4");
        }
        if (navigator.appVersion.indexOf("Mac") !== -1) {
            setOS("MacOS");
            setILink("https://support.apple.com/guide/messages/get-sms-texts-from-iphone-on-your-mac-icht8a28bb9a")
        }
        if (navigator.appVersion.indexOf("X11") !== -1 ||
            navigator.appVersion.indexOf("Linux") !== -1) {
            setOS("Linux");
            setILink("https://messages.google.com/web/authentication")
        }
        if (navigator.appVersion.indexOf("Chrome") !== -1 ||
            navigator.appVersion.indexOf("CrOS") !== -1 ||
            navigator.appVersion.indexOf("X11; C") !== -1) {
            setOS("Chrome");
            setILink("https://messages.google.com/web/authentication")
        }
    }, [setOS, setILink])

    return (
        <div className={classes.container}>
            <div className={classes.instructions} style={{
                borderColor: groupContext?.group.colors.primary_shade,
                color: groupContext?.group.colors.muted,
            }}>
                Need help setting up <br/>
                SMS texting for {os}?<br/>
                <a href={iLink} target={'_blank'} rel="noreferrer">Learn how to configure it here.</a>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            marginTop: 20
        },
        instructions:{
            marginTop: 15,
            borderWidth: 1,
            padding: 15,
            borderStyle: "solid",
            overflow: "hidden",
            borderRadius: 6,
            textAlign: "center"
        },
    }
});

export default MessageConfig;
