import React, {ReactElement} from 'react';
import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {MessageModel} from "../API/Models";
import {useMarkMessageAnswered} from "../API/MessageHooks";
import MessageChoiceBar from "./MessageChoiceBar";

type Props = {
    message: MessageModel
}

const MessageChoices = ({message}: Props): ReactElement => {
    const classes = useStyles();
    const [markAnswered] = useMarkMessageAnswered();

    const handleChoicePress = async (indx: number) =>{
        await markAnswered({message: message, choice: indx});
    }

    if(message.myShares===0) {
        return (
            <div>
                {message.attachment.choices.map((c, indx) => (
                    <div
                        key={`choice-${indx}`}
                        className={classes.buttonChoice}
                        onClick={(evt) => handleChoicePress(indx)}>
                        <p>{c.label}</p>
                    </div>
                ))}
            </div>
        );
    }else{
        return (
            <div>
                {message.attachment.choices.map((c,index)=>(
                    <MessageChoiceBar key={`choice-${index}`} message={message} choice={index} label={c.label} />
                ))}
            </div>
        )
    }
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        buttonChoice: {
            borderStyle: 'solid',
            borderWidth: 2,
            borderRadius: 20,
            padding: "8px 12px",
            margin: "4px 0",
            cursor: "pointer",
            backgroundColor: theme.palette.grey["50"],
            color: theme.palette.grey["700"],
            borderColor: theme.palette.grey["700"],
            "&:hover":{
                backgroundColor: theme.palette.background.default,
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            }
        }
    }
});

export default MessageChoices;
