import React, {ReactElement} from 'react';
import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {MessageModel} from "../API/Models";

type Props = {
    message: MessageModel;
    offset: number;
}

const AttachmentImage = ({message, offset}: Props): ReactElement => {
    const classes = useStyles();
    return (
        <div style={{marginTop: offset}}>
            {message.photos.length ? (
                <img className={classes.photo} alt={"message"} src={message.photos[0].path} />
            ) : null}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {},
        photo:{
            maxWidth: "100%",
            maxHeight: 120,
            marginTop: 10,
            marginBottom: -5,
            borderWidth: 1,
            borderStyle: "solid",
            overflow: "hidden",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            borderBottomWidth: 0,
        },
    }
});

export default AttachmentImage;
