import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {MessageModel} from "../API/Models";
import {GroupContext} from "../utils/GroupContext";
import {useGetMessageChoice} from "../API/MessageHooks";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

type Props = {
    message: MessageModel,
    choice: number,
    label: string
}

const MessageChoiceBar = ({message, choice, label}: Props): ReactElement => {
    const classes = useStyles();
    const groupContext = useContext(GroupContext);
    const {data, isLoading} = useGetMessageChoice(message.messageId, choice);
    const [isMyChoice, setIsMyChoice] = useState(false);
    const [count, setCount] = useState(0);
    const [percentage, setPercentage] = useState(0);

    useEffect(()=>{
        if(data){
            if(data.message_action && data.message_action.length>0) {
                setIsMyChoice(data.message_action[0].details.choice === choice);
            }
            setCount(data.choice_aggregate.aggregate.count);
            const p = Math.round((data.choice_aggregate.aggregate.count/ data.message_action_aggregate.aggregate.count)*100);
            setPercentage(p);
        }
    },[data, choice])

    if(isLoading){
        return <div></div>;
    }else {
        return (
            <div>
                <div className={classes.row}>
                    <div className={classes.bar} style={{backgroundColor: groupContext?.group.colors.track}}>
                        <div className={classes.choice} style={{
                            backgroundImage: `linear-gradient(0deg, ${groupContext?.group.colors.primary}, ${groupContext?.group.colors.primary_shade})`,
                            width: `${percentage}%`
                        }} />
                        <div className={classes.percentLabel}>
                            <p style={{color: "white", fontSize: 12}}>{percentage}%</p>
                        </div>
                    </div>
                    {isMyChoice?
                        <CheckBoxIcon style={{fontSize: 24, marginRight: 5, color: groupContext?.group.colors.accent}}/>
                        :
                        <CheckBoxOutlineBlankIcon style={{fontSize: 24, marginRight: 5, color: groupContext?.group.colors.track}}/>
                    }
                </div>
                <div className={classes.row}>
                    <p className={classes.label} style={{color: groupContext?.group.colors.muted}}>{label} ({count})</p>
                </div>
            </div>
        );
    }
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        row: {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            margin: 0
        },
        bar:{
            height: 24,
            flex: 1,
            flexDirection: "row",
            borderRadius: 12
        },
        choice:{
            height: 24,
            borderRadius: 12
        },
        percentLabel:{
            position: "absolute",
            left:10,
            top: 4
        },
        label:{
            flex: 1,
            fontSize: 12,
            margin: 0,
            padding: 0,
            paddingLeft: 10,
            paddingTop: 4,
            marginBottom: 15,
        }
    }
});

export default MessageChoiceBar;
