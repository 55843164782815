import React, {ReactElement} from 'react';
import { Theme, Dialog, DialogTitle, IconButton, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {ContactModel} from "../API/Models";
import {Formik, Form, Field} from 'formik';
import {Button, LinearProgress} from '@mui/material';
import {TextField} from 'formik-mui';
import CancelIcon from '@mui/icons-material/Cancel';

interface Props {
    contact: ContactModel | null;
    open: boolean;
    onClose: () => void;
}

const ContactDetail = ({contact, open, onClose}: Props): ReactElement => {
    const classes = useStyles();
    // const [selectedContact, setSelectedContact] = useState<ContactModel>(contact)

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle className={classes.title}>
                <div>Edit Contact</div>
                <IconButton onClick={onClose} size="large">
                    <CancelIcon fontSize={"large"}/>
                </IconButton>
            </DialogTitle>
            <div className={classes.container}>
                <Formik
                    initialValues={contact || {email: '', phone: ''}}
                    validate={values => {
                        const errors: Partial<ContactModel> = {};
                        if (!values.email) {
                            errors.email = 'Required';
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                        ) {
                            errors.email = 'Invalid email address';
                        }
                        return errors;
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        setTimeout(() => {
                            setSubmitting(false);
                            alert(JSON.stringify(values, null, 2));
                        }, 500);
                    }}
                >
                    {({submitForm, isSubmitting}) => (
                        <Form className={classes.form}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Field fullWidth
                                           component={TextField}
                                           name="firstName"
                                           type="text"
                                           label="First Name"
                                           disabled={contact?.source_id}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field fullWidth
                                           component={TextField}
                                           name="lastName"
                                           type="text"
                                           label="Last Name"
                                           disabled={contact?.source_id}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field fullWidth
                                           component={TextField}
                                           name="email"
                                           type="email"
                                           label="Email"
                                           disabled={contact?.source_id}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field fullWidth
                                           component={TextField}
                                           type="tel"
                                           label="Phone"
                                           name="phone"
                                           disabled={contact?.source_id}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {isSubmitting && <LinearProgress/>}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {},
        form: {
            padding: 15
        },
        title: {
            padding: "0 0 0 15px",
            "& h2": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }
        }
    }
});

export default ContactDetail;
