import React, {ReactElement, useContext, useState} from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {OrgModel} from "../API/Models";
import {useJoinCampaign, useLeaveCampaign, useMyCampaigns} from "../API/CampaignHooks";
import {GroupContext} from "../utils/GroupContext";
import Loading from "./Loading";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {useSnackbar} from "notistack";
import TeamsDialog from "./TeamsDialog";

interface Props {
    campaign: OrgModel;
}

const CampaignSubscription = ({campaign}: Props): ReactElement => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const groupContext = useContext(GroupContext);
    const [showTeamModal, setShowTeamModal] = useState(false);
    const {data, isLoading} = useMyCampaigns(groupContext?.group.group_code || '');
    const [join] = useJoinCampaign(campaign.networkId);
    const [leave] = useLeaveCampaign(campaign.networkId);

    if (!data || isLoading) {
        return <Loading/>
    }

    const isJoined = () => {
        if (data) {
            const matches = data.filter(c => {
                return c.networkId === campaign.networkId;
            });
            return matches.length > 0;
        } else {
            return false;
        }
    }

    const toggleFollowing = async () =>{
        if(campaign?.teams && campaign.teams.length>0) {
            setShowTeamModal(true);
            if(!isJoined()) {
                await join();
            }
        }else {
            if(isJoined()) {
                await leave();
                enqueueSnackbar(`Left ${campaign.name}`, {variant: "warning"});
            }else{
                await join();
                enqueueSnackbar(`Joined ${campaign.name}`, {variant: "success"});
            }
        }
    }

    // const handleJoinClick = async () => {
    //     if(campaign?.teams && campaign.teams.length>0) {
    //         setShowTeamModal(true);
    //         await join();
    //     }  else {
    //         await join();
    //         enqueueSnackbar(`Joined ${campaign.name}`, {variant: "success"});
    //     }
    // }
    //
    // const handleLeaveClick = () => {
    //     leave().then(result => {
    //         console.log(result);
    //         // refetch();
    //         enqueueSnackbar(`Left ${campaign.name}`, {variant: "warning"});
    //     });
    // }

    return (
        <div className={classes.container}>
            {isJoined() ?
                <div className={classes.actionButton}
                     onClick={toggleFollowing}>
                    <CheckCircleOutlineIcon/> &nbsp;
                    {(campaign?.teams && campaign.teams.length > 0) ?
                        <div>My Teams</div>
                        :
                        <div>Following</div>
                    }
                </div>
                :
                <div className={classes.actionButton}
                     onClick={toggleFollowing}
                     style={{
                         backgroundColor: "white",
                         border: `1px solid ${groupContext?.group.colors.primary}`
                     }}>
                    <i className={"fa fa-vote-yea"}/>&nbsp;
                    <div>Join</div>
                </div>
            }
            <TeamsDialog open={showTeamModal} onClose={()=>setShowTeamModal(false)} org={campaign} teams={campaign?.teams} user={campaign?.users[0]} />
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            marginRight: 10
        },
        actionButton: {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            borderRadius: 25,
            display: "flex",
            alignItems: "center",
            padding: "8px 16px",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
            }
        }
    }
});

export default CampaignSubscription;
