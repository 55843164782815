import React, {ReactElement, useContext} from 'react';
import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {MessageModel} from "../API/Models";
import {GroupContext} from "../utils/GroupContext";
import LinkIcon from '@mui/icons-material/Link';
import MessageChoices from "./MessageChoices";

interface Props {
    message: MessageModel;
}

const MessageAttachment = ({message}: Props): ReactElement => {
    const classes = useStyles();
    const groupContext = useContext(GroupContext);

    const getDomain = (url: string) => {
        if(url && url.length>5) {
            try {
                let domain = (new URL(url));
                return domain.hostname;
            }catch(e){
                console.error(e);
                return '';
            }
        }else{
            return '';
        }
    }

    const handleAttachmentUrl = async () => {
        if(message.attachment && message.attachment.url) {
            console.log(message.attachment.url);
            window.open(message.attachment.url);
        }
    }

    const PhotoStyleAttachment = (
        <div className={classes.messageWrapper} style={{borderColor: groupContext?.group.colors.track}}>
            {message.photos.length ? (
                <img className={classes.photo} alt={"message"} src={message.photos[0].path} style={{borderRadius: 6, overflow: "hidden"}} />
            ) : null}
            <div className={classes.postTitle} style={{color: groupContext?.group.colors.title}}>
                {message.title}
            </div>
            <div className={classes.postBody}>
                <div style={{color: groupContext?.group.colors.body}}>{message.message}</div>
            </div>
        </div>
    );

    const LinkStyleAttachment = (
        <div className={classes.messageWrapper}>
            <div className={classes.postTitle} style={{color: groupContext?.group.colors.title}}>
                {message.title}
            </div>
            <div className={classes.postBody}>
                <div style={{color: groupContext?.group.colors.body}}>{message.message}</div>
            </div>
            <div className={classes.attachment} style={{
                backgroundColor: groupContext?.group.colors.track,
                borderColor: groupContext?.group.colors.track,
            }} onClick={handleAttachmentUrl}>
                {message.attachment && message.attachment.image ? (
                    <img alt={"attachment preview"} src={message.attachment.image} className={classes.attachmentImage}/>
                ) : null}
                {message.attachment && message.attachment.title ? (
                    <div className={classes.urlTitle} style={{color: groupContext?.group.colors.title}}>
                        {message.attachment.title}
                    </div>
                ): null}
                {message.attachment && message.attachment.url ? (
                    <div className={classes.urlDomain}>
                        {message.attachment && message.attachment.favicon && message.attachment.favicon.length>0 ?
                            <img alt={"favicon"} src={message.attachment.favicon} width={16} height={16}/>
                            :
                            <LinkIcon height={16} width={16} style={{color: groupContext?.group.colors.muted}} />
                        }
                        <div className={classes.urlDomainText} style={{color: groupContext?.group.colors.title}}>
                            {getDomain(message.attachment.url)}
                        </div>
                    </div>
                ): null}
            </div>
        </div>
    );

    const ChoicesStyleAttachment = (
        <div className={classes.messageWrapper}>
            {message.photos.length ? (
                <img alt={message.title} src={message.photos[0].path} style={{borderRadius: 6, overflow: "hidden"}}/>
            ) : null}
            <p className={classes.postTitle}>
                {message.title}
            </p>
            <div className={classes.postBody}>
                {message.message.length>120?
                    <p>
                        <span style={{color: groupContext?.group.colors.body}}>{message.message}</span>
                    </p>
                    :
                    <p style={{color: groupContext?.group.colors.body}}>{message.message}</p>
                }
            </div>
            {message.attachment && message.attachment.choices && message.attachment.choices.length>0 ? (
                <MessageChoices message={message} />
            ): null}
        </div>
    );

    if (message.attachment && message.attachment.type) {
        switch (message.attachment.type) {
            case "message":
            case "form":
            case "page":
            case "url":
                return LinkStyleAttachment;
            case "polling":
                return ChoicesStyleAttachment;
            case "photo":
            default:
                return PhotoStyleAttachment;
        }
    } else {
        return PhotoStyleAttachment;
    }
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        messageWrapper: {
            flex: 1,
            padding: 0,
            marginTop: 10,
        },
        postTitle: {
            flexWrap: "wrap",
            fontWeight: "800",
            fontSize: 14,
            marginTop: 10,
            marginHorizontal: 10
        },
        postBody: {
            fontSize: 14,
            marginVertical: 15,
            marginHorizontal: 10
        },
        attachment:{
            marginTop: 15,
            borderWidth: 1,
            borderStyle: "solid",
            overflow: "hidden",
            borderRadius: 20,
            textAlign: "center",
            cursor: "pointer"
        },
        attachmentImage:{
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            alignSelf: "center",
            width: "100%",
            height: "auto"
        },
        urlTitle: {
            flexWrap: "wrap",
            fontWeight: "500",
            fontSize: 14,
            marginTop: 8,
            marginBottom: 4,
            marginLeft: 15,
            marginRight: 15,
            textAlign: "left"
        },
        urlDomain:{
            display: 'flex',
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 8,
            marginLeft: 15,
            marginRight: 15,
            textAlign: "left"
        },
        urlDomainText: {
            flexWrap: "wrap",
            fontWeight: "700",
            fontSize: 12,
            marginLeft: 5,
            textAlign: "left"
        },
        photo:{
            maxWidth: "100%",
            marginTop: 10
        },
    }
});

export default MessageAttachment;
