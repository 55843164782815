import React, {ReactElement} from 'react';
import {Dialog, DialogTitle, IconButton, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import CancelIcon from "@mui/icons-material/Cancel";
import {ContactModel, MessageModel} from "../API/Models";
import ContactHistory from "./chat/ContactHistory";
import ContactNewTextMessage from "./chat/ContactNewTextMessage";

interface Props {
	onClose: (success: boolean) => void;
	contact: ContactModel | null;
	message: MessageModel;
}

const ContactActionsSendDialog = ({contact, message, onClose}: Props): ReactElement => {
    const classes = useStyles();

	const handleShared = () => {
		onClose(true);
	}

    return (
		<Dialog maxWidth={"md"} onClose={()=>onClose(false)} open={contact!==null} className={classes.dialog}>
			<DialogTitle className={classes.title}>
				<div>Send Message to {contact?.firstName} {contact?.lastName}</div>
				<IconButton onClick={()=>onClose(false)} size="large">
					<CancelIcon fontSize={"large"}/>
				</IconButton>
			</DialogTitle>
			<div className={classes.container}>
				{contact?
					<div>
						<ContactHistory contact={contact} />
						<ContactNewTextMessage contact={contact} message={message} onShared={handleShared} />
					</div>
					:null}
			</div>
		</Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
		dialog: {},
		container: {
			maxWidth: "100%",
			padding: 15,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center"
		},
		title: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			padding: 0,
		},
    }
});

export default ContactActionsSendDialog;
