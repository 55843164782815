import {ContactModel, ProfileModel} from "../API/Models";

export function prepMessage(message: string, group_code: string, contact?: ContactModel, advocate?: ProfileModel) {
    if(contact) {
        message = (message || '').replace('{FIRST_NAME}', contact.firstName);
        message = (message || '').replace('{LAST_NAME}', contact.lastName);
        message = (message || '').replace('{EMAIL}', contact.email);
    }
    if(advocate){
        message = (message || '').replace('{ADVOCATE_FIRST_NAME}', advocate.firstName);
    }
    return (message || '').replace(
        /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi,
        function (match, space, url) {
            var hyperlink = url;
            console.log(url, ' => url');
            if (!hyperlink.match('^https?://')) {
                hyperlink = 'http://' + hyperlink;
            }
            const appendChar = hyperlink.indexOf('?') > -1 ? '&' : '?';
            return space + hyperlink + appendChar + 'utm_source=' + group_code;
        },
    );
}
