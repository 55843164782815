import React, {ReactElement, useContext, useState} from 'react';
import { Theme, TextField, Button, InputAdornment, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useNavigate} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {GroupContext} from "../utils/GroupContext";
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Cookie from "js-cookie";
import {queryCache} from "react-query";

interface Props {
    c?: any;
}

const LoginContainer = (props: Props): ReactElement => {
    const groupContext = useContext(GroupContext);
    const classes = useStyles();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [busy, setBusy] = useState(false);

    const login = async () => {
        try {
            setError('');
            await setBusy(true);
            Auth.configure({authenticationFlowType: 'USER_PASSWORD_AUTH'});
            const user = await Auth.signIn(username.toLowerCase(), password);
            // console.log(user);
            // await context?.setUserToken(user.signInUserSession.idToken.jwtToken);
            const token = user.signInUserSession.idToken.jwtToken;
            await Cookie.set("token", token, {expires: (1 / 24), sameSite: 'Lax'});
            await setBusy(false);
            const returnPath = Cookie.get('returnPath');
            if (returnPath) {
                Cookie.remove('returnPath');
                await queryCache.invalidateQueries('myProfile');
                await queryCache.invalidateQueries('myProfilePhotos');
                await queryCache.invalidateQueries('advocateMessages');
                await queryCache.invalidateQueries('orgMessages');
                await queryCache.invalidateQueries('groupMessages');
                navigate(returnPath);
            }else {
                navigate('/App/Groups');
            }
        } catch (err) {
            await setBusy(false);
            // @ts-ignore
            setError(err.message);
            console.log(err);
        }
    }

    const handleForgotClick = async () => {
        navigate('/Login/ForgotPassword');
    }

    return (
        <div className={classes.container}>
            <h2 className={classes.header} style={{color: groupContext?.group.colors.primary}}>
                Existing Account
            </h2>
            <TextField
                className={classes.textField}
                required
                id="email" name={"email"}
                label="Email"
                value={username}
                autoComplete={"username"}
                onChange={event => setUsername(event.target.value)}
                variant="outlined"
                disabled={busy}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <EmailIcon/>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                className={classes.textField}
                id="outlined-password-input"
                label="Password"
                type="password"
                value={password}
                onChange={event => setPassword(event.target.value)}
                autoComplete="current-password"
                variant="outlined"
                disabled={busy}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <LockIcon/>
                        </InputAdornment>
                    ),
                }}
            />
            <div className={classes.forgotLink}
                 style={{color: groupContext?.group.colors.primary}}
                 onClick={handleForgotClick}
            >
                <span>Forgot your password?</span>
            </div>
            <Button variant="contained" color="primary" onClick={login} disabled={busy}>
                {!busy
                    ? <span>Log in</span>
                    : <CircularProgress color={"secondary"}/>
                }
            </Button>
            <div className={classes.error}>
                {error && error.length>0 ?
                    <p>{error}</p>
                    : null}
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            borderRadius: 10,
            maxWidth: 350,
            padding: 20,
            margin: "auto"
        },
        header: {
            marginTop: 0,
            marginBottom: 20
        },
        textField: {
            marginBottom: "10px !important",
        },
        forgotLink: {
            marginBottom: 15,
            display: "flex",
            justifyContent: "flex-end",
            fontSize: 12,
            cursor: 'pointer',
        },
        error: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& p": {
                paddingTop: 10,
                color: "#cb2629"
            }
        }
    }
});

export default LoginContainer;
