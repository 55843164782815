import { parsePhoneNumber } from 'react-phone-number-input'

export interface GoogleContactGroup {
    name: string;
    formattedName: string;
    resourceName: string;
    memberCount: number;
}

export interface AdvocateContactModel {
    firstName: string;
    lastName: string;
    email: string;
    email2: string;
    phone: string;
    phone2: string;
    image: string;
    recordID: string;
}

export const mapGoogleContacts = (googleContacts: any) => {
    console.log(googleContacts);

    const getPhone = (contact: any, indx: number) =>{
        if(contact.phoneNumbers && contact.phoneNumbers.length > indx ){
            if(contact.phoneNumbers[indx].canonicalForm){
                const phoneNumber = parsePhoneNumber(contact.phoneNumbers[indx].canonicalForm);
                return phoneNumber?.nationalNumber;
            } else if(contact.phoneNumbers[indx].value){
                const phoneNumber = parsePhoneNumber(contact.phoneNumbers[indx].value);
                return phoneNumber?.nationalNumber;
            } else {
                return null;
            }
        }else{
            return null;
        }
    }

    let mapped: AdvocateContactModel[] = [];
    mapped = googleContacts.map((gc: any) => {
        if(gc.names) {
            return {
                firstName: gc.names ? gc.names[0].givenName : '',
                lastName: gc.names ? gc.names[0].familyName : '',
                email: gc.emailAddresses && gc.emailAddresses.length > 0 ? gc.emailAddresses[0].value : null,
                email2: gc.emailAddresses && gc.emailAddresses.length > 1 ? gc.emailAddresses[1].value : null,
                phone: getPhone(gc, 0),
                phone2: getPhone(gc, 1),
                image: gc.photos && gc.photos.length > 0 ? gc.photos[0].url : null,
                recordID: gc.resourceName,
            }
        }else{
            return null;
        }
    });
    return mapped.filter(Boolean);
}
