import React, {ReactElement, useEffect, useState} from 'react';
import {Theme, Grid, Paper, Typography, Button, Hidden, TextField} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MainWrapper from "../Components/MainWrapper";
import Loading from "../Components/Loading";
import TwoColumnWrapper from "../Components/TwoColumnWrapper";
import AvatarButton from "../Components/AvatarButton";
import {ProfileModel} from "../API/Models";
import GroupsList from "../Components/GroupsList";
import EmailIcon from '@mui/icons-material/Email';
import {useMyPhoto, useMyProfile, useSetProfile} from "../API/ProfileHooks";
import ResetPasswordContainer from "../Auth/ResetPasswordContainer";
import {Auth} from "aws-amplify";
import {useSnackbar} from "notistack";
import AuthWrapper from "../Components/AuthWrapper";
import PhoneInput from "react-phone-number-input";

interface Props {
    c?: any;
}

const Settings = (props: Props): ReactElement => {
    const IMAGE_PATH = '';
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [passwordState, setPasswordState] = useState('default');
    const {data, isLoading} = useMyProfile();
    const photoQuery = useMyPhoto();
    const [saveProfile] = useSetProfile();
    const [photo, setPhoto] = useState<string>('');
    const [profile, setProfile] = useState<ProfileModel | null>(null);
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        if (photoQuery.data && photoQuery.data.length > 0) {
            setPhoto(photoQuery.data[0].path)
        }
    }, [photoQuery.data, setPhoto])

    useEffect(() => {
        if (data) {
            setProfile(data)
        }
    }, [data, setProfile])

    if (!data || isLoading) {
        return <Loading/>
    }

    const handleImageChange = () => {
        alert('todo: handle image change')
    }

    const handlePasswordReset = async () => {
        try {
            if (profile) {
                await Auth.forgotPassword(profile.email);
                enqueueSnackbar("Password Reset Email Sent", {variant: "info"});
                setPasswordState('reset');
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar("Password Reset Failed", {variant: "error"});
            // @ts-ignore
            enqueueSnackbar(err.message, {variant: "error"});
        }
    }

    const handlePasswordChanged = async () => {
        setPasswordState('default');
        enqueueSnackbar("Password Changed", {variant: "success"});
    }

    const handleSave = async (evt: any) => {
        if (profile) {
            console.log('profile',profile);
            setTimeout(() => {
                setBusy(true);
                console.log(JSON.stringify(profile, null, 2));
                saveProfile(profile)
                    .then(res => {
                        console.log(res);
                        enqueueSnackbar("Profile Updated", {variant: "success"});
                    })
                    .catch(err => {
                        console.error(err);
                        enqueueSnackbar("Profile Save Failed", {variant: "error"});
                    })
                    .finally(() => {
                        setBusy(false);
                    })
            }, 500);
        }
    }

    return (
        <AuthWrapper>
            {profile ?
                <MainWrapper>
                    <TwoColumnWrapper sidebar={(
                        <div className={classes.sidebar}>
                            <div>
                                <img src={'/assets/settings.svg'} alt={'Settings Graphic'}
                                     className={classes.sidebarGraphic}/>
                            </div>
                            <p>
                                Make changes to your account or contact support for help.
                            </p>
                        </div>
                    )}>
                        <div className={classes.container}>
                            <Paper className={classes.paper}>
                                <h3>My Profile</h3>
                                <Grid container spacing={2} className={classes.container}>
                                    <Hidden mdDown>
                                        <Grid item sm={3}>
                                            <AvatarButton
                                                image={photo}
                                                email={profile.email}
                                                path={IMAGE_PATH}
                                                onChange={handleImageChange}/>
                                        </Grid>
                                    </Hidden>
                                    <Grid item sm={9} xs={12}>
                                        <Grid container direction={"row"} spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    name="firstName"
                                                    label="First Name"
                                                    value={profile.firstName || ''}
                                                    onChange={(evt) => {
                                                        setProfile({...profile, firstName: evt.target.value})
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    required fullWidth autoCapitalize="true"
                                                    autoFocus={true}/>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    name="lastName"
                                                    label="Last Name"
                                                    value={profile.lastName || ''}
                                                    onChange={(evt) => {
                                                        setProfile({...profile, lastName: evt.target.value})
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    required fullWidth autoCapitalize="true"/>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    name="postal"
                                                    label="Postal/Zip Code"
                                                    value={profile.postal || ''}
                                                    onChange={(evt) => {
                                                        setProfile({...profile, postal: evt.target.value})
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    required fullWidth/>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <PhoneInput
                                                    name="phone"
                                                    label="Phone Number"
                                                    required fullWidth className={classes.phoneField}
                                                    inputComponent={TextField}
                                                    type='tel'
                                                    smartCaret={false}
                                                    value={profile.phone || ''}
                                                    onChange={(e) => {
                                                        if (e) {
                                                            setProfile({...profile, phone: e.toString()})
                                                        }
                                                    }}
                                                    defaultCountry={'US'}
                                                    disabled={busy}
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.actionRow}>
                                                <Button
                                                    className={classes.button}
                                                    disabled={busy}
                                                    onClick={handleSave}
                                                >
                                                    Save Changes
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>

                            <Paper className={classes.paper}>
                                <h3>My Login</h3>
                                <div className={classes.row}>
                                    <div className={classes.email}>
                                        <EmailIcon className={classes.emailIcon}/>
                                        <Typography color={'textSecondary'} className={classes.emailText}>
                                            {profile.email}
                                        </Typography>
                                    </div>
                                    <div className={classes.button} onClick={handlePasswordReset}>
                                        Reset My Password
                                    </div>
                                </div>
                                {passwordState === 'reset' ?
                                    <div>
                                        <p>
                                            A password reset confirmation code has been emailed to you,
                                            fill out the form below after you receive it.
                                        </p>
                                        <ResetPasswordContainer email={profile.email}
                                                                onChange={handlePasswordChanged}/>
                                    </div>
                                    : null}
                            </Paper>

                            <Paper className={classes.paper}>
                                <div className={classes.row}>
                                    <h3>My Groups</h3>
                                </div>
                                <GroupsList allowNew={true} allowRemove={true}/>
                            </Paper>

                        </div>
                    </TwoColumnWrapper>
                </MainWrapper>
                :
                <></>
            }
        </AuthWrapper>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            flex: 1,
        },
        form: {},
        paper: {
            flex: 1,
            padding: 20,
            marginBottom: 20,
            "& h3": {
                marginTop: 0,
            }
        },
        row: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        actionRow: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: 20
        },
        email: {
            flex: 1,
            marginRight: 20,
            display: "flex",
            alignItems: "center",
            border: "1px solid #efefef",
            borderRadius: 8,
            padding: 10,
        },
        emailIcon: {
            marginRight: 10,
            color: "#a0a0a0"
        },
        emailText: {},
        sidebar: {
            flex: 1,
        },
        sidebarGraphic: {
            width: "calc(100% - 20px)",
            margin: 10
        },
        sidebarActions: {
            display: "flex",
            justifyContent: "space-between"
        },
        button: {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            borderRadius: 25,
            display: "flex",
            alignItems: "center",
            padding: "8px 16px",
            cursor: "pointer",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 12,
            lineHeight: 1.5,
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
            }
        },
        clickable: {
            cursor: "pointer",
            "&:hover": {
                color: "rgba(0, 0, 0, 0.5)",
            }
        },
        phoneField: {
            flexDirection: "row-reverse",
            "& .MuiOutlinedInput-root": {
                marginRight: -40
            },
            marginBottom: 10,
        },
    }
});

export default Settings;
