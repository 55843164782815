import {useQuery, useMutation, queryCache} from "react-query";
import {GroupModel, WrappedGroupModel} from "./Models";
import {useAuthClient, useClient} from "./useClient";

export function useMyGroups() {
    const client = useAuthClient();

    return useQuery<WrappedGroupModel[]>('myGroups', async () => {
        try {
            if(client){
                const response = await client.get(`/groups`);
                return response.data;
            } else {
                return [];
            }
        } catch (err) {
            console.error(err);
        }
    });
}

export function useGetGroup(group_code: string | undefined) {
    const client = useClient();
    return useQuery<GroupModel>(['getGroup', group_code], async () => {
        try {
            if (group_code && client) {
                const response = await client.get(`/groups/${group_code}`);
                return response.data[0];
            } else {
                return null;
            }
        } catch (err) {
            console.error(err);
        }
    });
}

export function useJoinGroup() {
    const client = useAuthClient();

    return useMutation(async (group_code: string) => {
        try {
            if (group_code && client) {
                const response = await client.post(`/groups/${group_code}`);
                await queryCache.invalidateQueries('myGroups');
                return response.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error(err);
        }
    }, {
        onSuccess: () => {

        },
        onError: (err) => {
            console.error(err);
        }
    });
}

export function useUpdateGroup() {
    const client = useAuthClient();

    interface updateType {
        group_code: string,
        id: number,
        digestEnabled: boolean,
    }

    return useMutation(async (data: updateType) => {
        try {
            if (data.group_code && client) {
                const response = await client.put(`/groups/${data.group_code}`, {
                    id: data.id,
                    digestEnabled: data.digestEnabled
                });
                await queryCache.invalidateQueries('myGroups');
                return response.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error(err);
        }
    }, {
        onSuccess: () => {

        },
        onError: (err) => {
            console.error(err);
        }
    });
}

export function useLeaveGroup() {
    const client = useAuthClient();

    return useMutation(async (group_code: string) => {
        try {
            if (group_code && client) {
                const response = await client.delete(`/groups/${group_code}`);
                await queryCache.invalidateQueries('myGroups');
                return response.data;
            }else{
                return null;
            }
        } catch (err) {
            console.error(err);
        }
    }, {
        onSuccess: () => {

        },
        onError: (err) => {
            console.error(err);
        }
    });
}
