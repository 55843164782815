import React, {ReactElement, useContext, useEffect, useState} from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MainWrapper from "../Components/MainWrapper";
import {NavLink} from "react-router-dom";
import {GroupContext} from "../utils/GroupContext";
import CampaignsList from "../Components/CampaignsList";
import Cookie from "js-cookie";
import MessagesList from "../Components/MessagesList";
import AuthWrapper from "../Components/AuthWrapper";

interface Props {
    c?: any;
}

const Home = (props: Props): ReactElement => {
    const classes = useStyles();
    const groupContext = useContext(GroupContext);
    const group = groupContext?.group;
    const [userToken, setUserToken] = useState(Cookie.get("token"));

    useEffect(() => {
            // console.log('useEffect', group);
        if (!userToken || userToken.length===0) {
            const token = Cookie.get("token");
            if(token) {
                setUserToken(token);
            }
        }
    }, [group, userToken]);

    if (userToken) {
        return (
            <AuthWrapper>
                <MainWrapper>
                    <div className={classes.container}>
                        <h3>My Campaigns</h3>
                        <CampaignsList />
                        <h3>Messages</h3>
                        <MessagesList/>
                    </div>
                </MainWrapper>
            </AuthWrapper>
        );
    } else {
        return (
            <MainWrapper>
                <div className={classes.container}>
                    <div className={classes.underlay}>
                        <h1>Let's make a difference together</h1>
                        <div className={classes.actionStrip}>
                            <NavLink to={'/Login'} className={classes.button}>
                                <img src={'/assets/left-arrow-grey.png'} alt={'Login'}/>
                                <p>Login</p>
                            </NavLink>
                            <NavLink to={'/Signup'} className={classes.button}>
                                <img src={'/assets/right-arrow-red.png'} alt={'Sign Up'}/>
                                <p>Sign Up</p>
                            </NavLink>
                        </div>
                        <div>
                            <img className={classes.logo} src={groupContext?.group.logo} alt={groupContext?.group.name}/>
                        </div>
                    </div>
                    {/*<div className={classes.roundedBlock}>*/}
                    {/*    <img className={classes.mobileImage} src={'/assets/pillars.jpg'}/>*/}
                    {/*    <h2>Let's make a difference together</h2>*/}
                    {/*    <div className={classes.actionStrip} style={{backgroundImage: `linear-gradient(0deg, ${groupContext?.group.colors.primary}, ${groupContext?.group.colors.primary_shade})`}}>*/}
                    {/*        <NavLink to={'/Login'} className={classes.button}>*/}
                    {/*            <img src={'/assets/left-arrow-grey.png'} alt={'Login'}/>*/}
                    {/*            <p>Login</p>*/}
                    {/*        </NavLink>*/}
                    {/*        <NavLink to={'/Signup'} className={classes.button}>*/}
                    {/*            <img src={'/assets/right-arrow-red.png'} alt={'Sign Up'}/>*/}
                    {/*            <p>Sign Up</p>*/}
                    {/*        </NavLink>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </MainWrapper>
        );
    }
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container:{
            display: "flex",
            flexDirection: "column",
            margin: "auto",
        },
        underlay:{
            position: "fixed",
            top: 0, left: 0, right: 0, bottom: 0,
            backgroundImage: 'url(/assets/hands.jpg)',
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            "& h1":{
                textAlign: "center",
                color: "white",
                textShadow: "1px 1px 1px black"
            }
        },
        roundedBlock: {
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%",
            width: 500,
            backgroundColor: "white",
            overflow: "hidden",
            borderRadius: 4,
            margin: "auto",
            alignItems: "center",
            justifyContent: "center",
            "& h2":{
                textAlign: "center"
            }
        },
        header:{
            width: "100%",
            height: 64,
            padding: "10px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        logo:{
            maxWidth: 300,
            height: 80,
            margin: "30px auto",
        },
        mobileImage: {
            maxWidth: "70%",
            height: "auto"
        },
        actionStrip: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 0"
        },
        button: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            border: "1px solid silver",
            margin: "0 10px",
            padding: 10,
            width: 90,
            cursor: "pointer",
            textDecoration: "none",
            borderRadius: 30,
            "&:hover": {
                backgroundColor: "#efefef",
            },
            "& img": {
                width: 32,
                marginBottom: 10,
            },
            "& p": {
                margin: 0,
                fontWeight: "bold",
                color: "#777"
            }
        }
    }
});

export default Home;
