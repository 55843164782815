import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import awsconfig from './Config';
import {Amplify} from "aws-amplify";
import {SnackbarProvider} from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles'

Amplify.configure(awsconfig);

const theme = createTheme({
    palette: {
        primary: { main: '#a61012' },
        secondary: { main: '#ffbd46' },
    }
});

const container = document.getElementById('root');
if(container) {
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                    <App/>
                </SnackbarProvider>
            </ThemeProvider>
        </React.StrictMode>
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
