import {useEffect, useState} from "react";

const CLIENT_ID = '99904206776-0kt08dma1pqcfrq26jhahr7h25j8bkgh.apps.googleusercontent.com';
const API_KEY = 'AIzaSyBBwO2K42OrA4mMAxR2j0zTy9vdurTTTyE';
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/people/v1/rest"];
const SCOPES = "https://www.googleapis.com/auth/contacts.readonly";

export const useGoogleSDK = () => {
    const [loaded, setLoaded] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [signedIn, setSignedIn] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [groups, setGroups] = useState([]);
    const [activeGroup, setActiveGroup] = useState();

    useEffect(()=>{
        if(!loaded){
            console.log('loading gapi');
            window.gapi.load('client:auth2', ()=>{console.log('gapi loaded')});
            setLoaded(true);
        }
    },[loaded, setLoaded])

    const init = () => {
        window.gapi.client.init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES
        }).then(function () {
            // Listen for sign-in state changes.
            window.gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
            setInitialized(true);
            // Handle the initial sign-in state.
            updateSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get());
        }, function(error) {
            console.error(error);
        });
    }

    const updateSigninStatus = (isSignedIn) => {
        setSignedIn(isSignedIn);
        if (isSignedIn) {
            getContacts();
            getContactGroupList();
        }
    }

    const signIn = () =>{
        if(initialized) {
            window.gapi.auth2.getAuthInstance().signIn();
        }else{
            init();
        }
    }

    const signOut = () =>{
        window.gapi.auth2.getAuthInstance().signOut();
    }

    function getContactGroupList() {
        window.gapi.client.people.contactGroups.list({
        }).then(function(response) {
            setGroups(response.result.contactGroups);
        });
    }

    async function getContactGroup(resourceName) {
        return await window.gapi.client.people.contactGroups.get({
            'resourceName': resourceName,
            'maxMembers': 4000,
        }).then(async function(response) {
            setActiveGroup(resourceName);
            //return await getContactGroupMembers(response.result.memberResourceNames);
            const members = contacts.map(c=>{
                if(response.result.memberResourceNames.indexOf(c.resourceName)>=0){
                    return c;
                }else{
                    return null;
                }
            })
            return members.filter(Boolean);
        });
    }

    // can only get 50 records at a time.
    // async function getContactGroupMembers(resourceNames) {
    //     return await window.gapi.client.people.people.getBatchGet({
    //         'resourceNames': resourceNames,
    //         'personFields': 'names,emailAddresses,phoneNumbers,metadata,photos',
    //     }).then(async function(response) {
    //         setContacts(response.result.responses);
    //         return response.result.responses;
    //     });
    // }

    function getContacts() {
        window.gapi.client.people.people.connections.list({
            'resourceName': 'people/me',
            'pageSize': 2000,
            'personFields': 'names,emailAddresses,phoneNumbers,metadata,photos',
        }).then(function(response) {
            setContacts(response.result.connections);
        });
    }

    // window.gapi.load('client:auth2', init);

    return {
        signedIn: signedIn,
        googleContacts: contacts,
        googleContactGroups: groups,
        activeContactGroup: activeGroup,
        init: init,
        signIn: signIn,
        signOut: signOut,
        fetchContactGroup: getContactGroup,
        fetchAll: getContacts,
    }
}
