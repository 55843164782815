import React, {ChangeEvent, ReactElement, useEffect, useState} from 'react';
import { Theme, Paper, InputBase } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Pagination } from '@mui/material';
import {ContactModel, MessageModel} from "../API/Models";
import {useMessageContacts, useMyContacts} from "../API/ContactHooks";
import ContactActionsList from "./ContactActionsList";
import SearchIcon from '@mui/icons-material/Search';
import MessageP2PContacts from "./MessageP2PContacts";

interface Props {
    message: MessageModel;
}

const MessageContacts = ({message}: Props): ReactElement => {
    const classes = useStyles();
    const PAGE_SIZE=25;
    const myContacts = useMyContacts()
    const messageContacts = useMessageContacts(message.messageId);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [contacts, setContacts] = useState<ContactModel[]>([]);
    const [pageContacts, setPageContacts] = useState<ContactModel[]>([]);

    useEffect(()=>{
        if(myContacts.data) {
            let filtered = myContacts.data;
            if (searchTerm && searchTerm.length > 0) {
                filtered = myContacts.data.filter(contact=>{
                    return (
                        (
                            contact.firstName.toLowerCase() +
                            " " +
                            contact.lastName.toLowerCase()
                        ).indexOf(searchTerm.toLowerCase()) >= 0
                    );
                })
            }
            setContacts(filtered);
            const start =(page-1)*PAGE_SIZE;
            let end = (page-1)*PAGE_SIZE + PAGE_SIZE;
            if(end>filtered.length) { end = filtered.length;}
            setPageContacts(filtered.slice(start, end));
        }
    },[myContacts, setContacts, setPageContacts, searchTerm, page])

    const handlePageChange = (event: object, page: number)=> {
        setPage(page);
    }

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement> )=> {
        setPage(1);
        setSearchTerm(event.target.value);
    }

    return (
        <div className={classes.container}>
            <Paper component="form" className={classes.searchBox}>
                <InputBase
                    onChange={handleSearchChange}
                    className={classes.input}
                    placeholder="Search Contacts"
                    inputProps={{ 'aria-label': 'search contacts' }}
                    autoFocus={true}
                />
                <SearchIcon className={classes.iconButton} />
            </Paper>
            {messageContacts.data && (!searchTerm || searchTerm.length===0) ?
                <div>
                    <h3>Message Smart Matches ({messageContacts.data.length})</h3>
                    <div>
                        <ContactActionsList contacts={messageContacts.data} message={message}/>
                    </div>
                </div>
                : null
            }
            {(!searchTerm || searchTerm.length===0) && message.isP2P ?
                <MessageP2PContacts message={message} />
                : null
            }
            {myContacts.data ?
                <div>
                    {!searchTerm || searchTerm.length===0 ?
                        <h3>All My Contacts ({myContacts.data.length})</h3>
                        :
                        <h3>Contact Search Matches ({contacts.length})</h3>
                    }
                    <div>
                        <ContactActionsList contacts={pageContacts} message={message}/>
                        <div className={classes.pager}>
                            <Pagination
                                count={Math.ceil(contacts.length/PAGE_SIZE)}
                                page={page}
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
                : null
            }
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            flex: 1,
            "& h3":  {
                marginTop: 40,
                marginBottom: 10
            }
        },
        searchBox: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: "100%",
            margin: "25px 0"
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        pager:{
            width: "100%",
            marginTop: 20,
            display: "flex",
            justifyContent: "flex-end",
        }
    }
});

export default MessageContacts;
