import {useQuery, useMutation} from "react-query";
import {PhotoModel, ProfileModel} from "./Models";
import {useAuthClient} from "./useClient";

export function useMyProfile() {
    const client = useAuthClient();

    return useQuery<ProfileModel>(['myProfile'], async () => {
        try {
            if(client) {
                const response = await client.get(`/profile`);
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            console.error(err);
        }
    });
}

export function useMyPhoto() {
    const client = useAuthClient();

    return useQuery<PhotoModel[]>(['myProfilePhotos'], async () => {
        try {
            if(client){
                const response = await client.get(`/profile/photos`);
                return response.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error(err);
        }
    });
}

export function useSetProfile() {
    const client = useAuthClient();

    return useMutation( async (profile: ProfileModel) => {
        try {
            if (profile && client) {
                const response = await client.post(`/profile`, profile);
                return response.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error(err);
        }
    });
}

export function useSetPhoto() {
    const client = useAuthClient();

    return useMutation( async (photo: PhotoModel) => {
        try {
            if (photo && client) {
                const response = await client.post(`/profile/photos`, photo);
                return response.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error(err);
        }
    });
}
