import React, {ChangeEvent, ReactElement, useState} from 'react';
import { Theme, Paper, InputBase, FormControlLabel, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MainWrapper from "../Components/MainWrapper";
import TwoColumnWrapper from "../Components/TwoColumnWrapper";
import SearchIcon from "@mui/icons-material/Search";
import GoogleContactsDialog from "../Components/GoogleContactsDialog";
import ContactsActive from "../Components/ContactsActive";
import ContactsDeleted from "../Components/ContactsDeleted";
import AuthWrapper from "../Components/AuthWrapper";

interface Props {
    c?: any;
}

const Contacts = (props: Props): ReactElement => {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState('');
    const [showDeleted, setShowDeleted] = useState(false);
    const [showGoogleDialog, setShowGoogleDialog] = useState(false);


    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }

    const handleGoogleDialogOpen = () => {
        setShowGoogleDialog(true);
    }

    const handleGoogleDialogClose = () => {
        setShowGoogleDialog(false);
    }

    const handleShowDeleted = (event: any) => {
        setShowDeleted(event.target.checked);
    }

    return (
        <AuthWrapper>
            <MainWrapper>
                <TwoColumnWrapper sidebar={ (
                    <div className={classes.sidebar}>
                        <div>
                            <img src={'/assets/data_sync.svg'} alt={'Contacts Graphic'} className={classes.sidebarGraphic} />
                        </div>
                        <div className={classes.sidebarActions}>
                            <div className={classes.button} onClick={handleGoogleDialogOpen}>
                                <img src={'/icons/google.png'} alt={'Google'} width={24}/>
                                <div>Import Google Contacts</div>
                            </div>
                            <GoogleContactsDialog open={showGoogleDialog} onClose={handleGoogleDialogClose} />
                        </div>
                        <p>
                            Connect your contacts, your outreach will be 9 times more when your contacts are connected!
                            Messaging your friends and family is the most effective thing you can do to make an impact.
                        </p>
                        <div className={classes.switchWrapper}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showDeleted}
                                        onChange={handleShowDeleted}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Show Deleted Contacts"
                            />
                        </div>
                    </div>
                )}>
                    <div className={classes.container}>
                        <Paper component="form" className={classes.searchBox}>
                            <InputBase
                                onChange={handleSearchChange}
                                className={classes.input}
                                placeholder="Search Contacts"
                                inputProps={{'aria-label': 'search contacts'}}
                                autoFocus={true}
                            />
                            <SearchIcon className={classes.iconButton}/>
                        </Paper>
                        {showDeleted ?
                            <ContactsDeleted searchTerm={searchTerm} />
                            :
                            <ContactsActive searchTerm={searchTerm} />
                        }
                    </div>
                </TwoColumnWrapper>
            </MainWrapper>
        </AuthWrapper>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            flex: 1,
            "& h3": {
                marginTop: 40,
                marginBottom: 10
            }
        },
        searchBox: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: "100%",
            marginBottom: 25,
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        sidebar: {
            flex: 1,
        },
        sidebarGraphic: {
            width: "calc(100% - 20px)",
            margin: 10
        },
        sidebarActions: {
            display: "flex",
            justifyContent: "space-between"
        },
        switchWrapper: {
            textAlign: "center",
        },
        button: {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            borderRadius: "25px !important",
            overflow: "hidden",
            display: "flex",
            alignItems: "center !important",
            padding: "8px 16px 8px 8px",
            cursor: "pointer",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: "12px !important",
            lineHeight: 1.5,
            "& img": {
                marginRight: 10
            },
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
            }
        }
    }
});

export default Contacts;
