import React, {ReactElement} from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MainWrapper from "../Components/MainWrapper";
import {useNavigate, useParams} from "react-router-dom";
import SignUpContainer from "../Auth/SignUpContainer";
import {useGroupTheme} from "../API/useGroupTheme";

interface Props {
    c?: any;
}

const Signup = (props: Props): ReactElement => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {groupCode} = useParams();
    useGroupTheme(groupCode);

    const handleLoginClick = () =>{
        navigate('/Login');
    }

    return (
        <MainWrapper>
            <div>
                <div className={classes.underlay} />
                <div className={classes.container}>
                    <div className={classes.signupLink} onClick={handleLoginClick}>
                        <span>Already have an account?</span>
                    </div>
                    <SignUpContainer />
                </div>
            </div>
        </MainWrapper>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            width: "100%",
            maxWidth: 350,
            paddingTop: 20,
            margin: "auto",
            position: "relative",
            zIndex: 2
        },
        underlay:{
            position: "fixed",
            zIndex: 1,
            top: 0, left: 0, right: 0, bottom: 0,
            backgroundImage: 'url(/assets/hands.jpg)',
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& h1":{
                textAlign: "center",
                color: "white",
                textShadow: "1px 1px 1px black"
            }
        },
        signupLink:{
            paddingBottom: 20,
            color: "white",
            textShadow: "1px 1px 3px black",
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
        }
    }
});

export default Signup;
