import React, {ReactElement, useContext} from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {useAdvocateMessages} from "../API/MessageHooks";
import {NavLink, useParams} from "react-router-dom";
import {GroupContext} from "../utils/GroupContext";

interface Props {
    c?: any;
}

const NotificationsIndicator = (props: Props): ReactElement => {
    const classes = useStyles();
    const groupContext = useContext(GroupContext);
    const {groupCode} = useParams();
    const {data} = useAdvocateMessages(false, groupCode);

    if(data && data.length>0) {
        return (
            <NavLink to={`/App/${groupContext?.group.group_code}/Inbox`}>
                <NotificationsActiveIcon fontSize={"large"} className={classes.bellIcon}/>
            </NavLink>
        );
    }else {
        return (
            <div className={classes.container}>
                <NotificationsNoneIcon fontSize={"large"} className={classes.bellIcon}/>
            </div>
        );
    }
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: "flex",
            alignItems: "center"
        },
        bellIcon: {
            color: "white"
        }
    }
});

export default NotificationsIndicator;
