import React, {ReactElement} from 'react';
import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LoginContainer from "../Auth/LoginContainer";
import MainWrapper from '../Components/MainWrapper';
import ForgotPasswordContainer from "../Auth/ForgotPasswordContainer";
import ResetPasswordContainer from "../Auth/ResetPasswordContainer";
import {useGroupTheme} from "../API/useGroupTheme";

interface Props {
    c?: any;
}

const Login = (props: Props): ReactElement => {
    const classes = useStyles();
    const {groupCode} = useParams();
    const navigate = useNavigate();
    useGroupTheme(groupCode);

    const handleSignupClick = () =>{
        navigate('/Signup');
    }

    return (
        <MainWrapper>
            <div>
                <div className={classes.underlay} />
                <div className={classes.container}>
                    <div className={classes.signupLink} onClick={handleSignupClick}>
                        <span>Don't have an account?</span>
                    </div>
                    <Routes>
                        <Route path="/" element={<LoginContainer/>} />
                        <Route path="/ForgotPassword" element={<ForgotPasswordContainer/>} />
                        <Route path="/ResetPassword" element={<ResetPasswordContainer />} />
                        <Route path="/:groupCode" element={<LoginContainer/>} />
                    </Routes>
                </div>
            </div>
        </MainWrapper>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            width: "100%",
            maxWidth: 350,
            paddingTop: 20,
            margin: "auto",
            position: "relative",
            zIndex: 2
        },
        underlay:{
            position: "fixed",
            zIndex: 1,
            top: 0, left: 0, right: 0, bottom: 0,
            backgroundImage: 'url(/assets/hands.jpg)',
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& h1":{
                textAlign: "center",
                color: "white",
                textShadow: "1px 1px 1px black"
            }
        },
        signupLink:{
            paddingBottom: 20,
            color: "white",
            textShadow: "1px 1px 3px black",
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
        }
    }
});

export default Login;
