import {queryCache, useMutation, useQuery} from "react-query";
import {ContactModel, AdvocateContactActionsModel} from "./Models";
import {useAuthClient} from "./useClient";

export function useGetContactActions(contact: ContactModel | undefined) {
    const client = useAuthClient();

    return useQuery<AdvocateContactActionsModel[]>(['contactActions', contact?.id], async () => {
        try {
            if (contact && client) {
                const response = await client.get(`/actions/contact/${contact.id}`);
                return response.data;
            } else {
                return [];
            }
        } catch (err) {
            console.error('ActionHooks.useGetContactActions', err);
        }
    });
}

export function useCreateContactAction() {
    const client = useAuthClient();

    interface createActionType {
        contact_id: string,
        message_id: string,
        action: string,
        share_type: string,
        message: string,
        image: string | null,
        unique: boolean
    }

    const update = async (createData: createActionType) => {
        try {
            if (createData && client) {
                const response = await client.post(`/actions/contact/${createData.contact_id}`, createData);
                await queryCache.invalidateQueries(['contactActions', createData.contact_id]);
                await queryCache.invalidateQueries(['messageP2PContacts', createData.message_id]);
                return response.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error('ActionHooks.useCreateContactAction', err);
        }
    }

    return useMutation(update);
}
