import React, {ReactElement, useEffect, useState} from 'react';
import {Theme, FormControl, InputLabel, Select, MenuItem, Button, SelectChangeEvent} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ContactList from "./ContactList";
import { Pagination } from '@mui/material';
import {ContactModel} from "../API/Models";
import Loading from "./Loading";
import {useMarkDeleted, useMyDeletedContacts} from "../API/ContactHooks";
import ContactDetail from "./ContactDetail";
import {useSnackbar} from "notistack";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

interface Props {
    searchTerm: any;
}

const ContactsDeleted = ({searchTerm}: Props): ReactElement => {
    const classes = useStyles();
    const {data, isLoading} = useMyDeletedContacts();
    const [pageSize, setPageSize] = useState("15");
    const [page, setPage] = useState(1);
    const [contacts, setContacts] = useState<ContactModel[]>([]);
    const [pageContacts, setPageContacts] = useState<ContactModel[]>([]);
    const [activeContact, setActiveContact] = useState<ContactModel | null>(null);
    const [selected, setSelected] = useState<string[]>([]);
    const [busy, setBusy] = useState(false);
    const [markDeleted] = useMarkDeleted();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (data) {
            let filtered = data;
            if (searchTerm && searchTerm.length > 0) {
                filtered = data.filter(contact => {
                    return (
                        (
                            contact.firstName.toLowerCase() +
                            " " +
                            contact.lastName.toLowerCase()
                        ).indexOf(searchTerm.toLowerCase()) >= 0
                    );
                })
            }
            setContacts(filtered);
            const start = (page - 1) * parseInt(pageSize);
            let end = (page - 1) * parseInt(pageSize) + parseInt(pageSize);
            if (end > filtered.length) {
                end = filtered.length;
            }
            setPageContacts(filtered.slice(start, end));
        }
    }, [data, setContacts, setPageContacts, searchTerm, page, pageSize])

    if (isLoading) {
        return <Loading/>
    }

    const handlePageChange = (event: object, page: number) => {
        setPage(page);
    }

    const handlePageSizeChange = (event: SelectChangeEvent) => {
        setPageSize(event.target.value);
    };

    const handleContactEdit = (contact: ContactModel) => {
        setActiveContact(contact);
    }

    const handleContactClose = () => {
        setActiveContact(null);
    }

    const handleSelected = (value: string[]) =>{
        setSelected(value);
    }

    const handleRestore = (event: any) =>{
        setBusy(true);
        markDeleted({ids: selected, deleted: false})
            .then(res=>{
                enqueueSnackbar(`${selected.length} Contacts Restored`, {variant: "success"});
                setSelected([]);
                setBusy(false);
            })
            .catch(err=>{
                console.error(err);
                setBusy(false);
                enqueueSnackbar("Restore Failed", {variant: "error"});
            });
    }

    if (data) {

        return (
            <div>
                {!searchTerm || searchTerm.length === 0 ?
                    <h3>Deleted Contacts ({data.length})</h3>
                    :
                    <h3>Deleted Matches ({contacts.length})</h3>
                }
                <div>
                    <ContactList
                        contacts={pageContacts}
                        onEdit={handleContactEdit}
                        onSelected={handleSelected}
                        actionButton={
                            <Button
                                color={'primary'}
                                variant={'contained'}
                                startIcon={<RestoreFromTrashIcon/>}
                                onClick={handleRestore}
                                disabled={busy}
                            >
                                Restore {selected.length} Contacts
                            </Button>
                        }
                    />
                    <div className={classes.pager}>
                        <FormControl variant="outlined" size={"small"} className={classes.formControl}>
                            <InputLabel>Page Size</InputLabel>
                            <Select
                                value={pageSize}
                                onChange={handlePageSizeChange}
                                label="Page Size"
                            >
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                        <Pagination
                            className={classes.pagerControl}
                            count={Math.ceil(contacts.length / parseInt(pageSize))}
                            page={page}
                            onChange={handlePageChange}
                        />
                    </div>
                </div>
                <ContactDetail contact={activeContact} open={activeContact!==null} onClose={handleContactClose} />
            </div>
        );
    } else {
        return (<div></div>);
    }
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {},
        pager: {
            width: "100%",
            marginTop: theme.spacing(3),
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        pagerControl: {},
        formControl: {
            minWidth: 100,
        },
    }
});

export default ContactsDeleted;
