import React, {ReactElement, useContext} from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {MessageModel} from "../API/Models";
import {NavLink} from "react-router-dom";
import {GroupContext} from "../utils/GroupContext";
import {useMyCampaigns} from "../API/CampaignHooks";

interface Props {
    message: MessageModel;
}

const MessageAction = ({message}: Props): ReactElement => {
    const classes = useStyles();
    const groupContext = useContext(GroupContext);
    const {data} = useMyCampaigns(groupContext?.group.group_code || '');

    const isJoined = () => {
        if (data) {
            const matches = data.filter(c => {
                return c.networkId === message.network.networkId;
            });
            return matches.length > 0;
        } else {
            return false;
        }
    }

    if(isJoined()) {
        return (
            <div className={classes.container}>
                {message.isF2F ? (
                    <NavLink
                        to={`/App/${groupContext?.group.group_code}/Message/${message.messageId}`}
                        className={classes.actionButton} style={{color: groupContext?.group.colors.primary_shade}}
                    >
                        Share to Contacts&nbsp;
                        <i className={"fa fa-share"}/>
                    </NavLink>
                ) : null}
                {message.isPost ? (
                    <NavLink
                        to={`/App/${groupContext?.group.group_code}/Message/${message.messageId}`}
                        className={classes.actionButton} style={{color: groupContext?.group.colors.primary_shade}}
                    >
                        Share Online&nbsp;
                        <i className={"fa fa-share-alt"}/>
                    </NavLink>
                ) : null}
            </div>
        );
    }else{
        return (
            <div className={classes.joinMessage}>
                Join '{message.network.name}' above to Share
            </div>
        )
    }
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            paddingTop: 10,
            flex: 1,
            display: "flex"
        },
        actionButton:{
            flex: 1,
            textAlign: "right",
            color: "#333",
            padding: "8px 16px",
            textDecoration: "none",
            "&:hover": {
                textDecoration: "underline"
            }
        },
        joinMessage:{
            flex: 1,
            textAlign: "center",
            color: "#777",
            paddingTop: 16,
        }
    }
});

export default MessageAction;
