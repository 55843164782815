import React, {ReactElement, useState, useEffect} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Switch, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from '@mui/icons-material/Check';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {OrgModel, OrgTeamModel, OrgUserModel} from "../API/Models";
import {useJoinOrgTeam, useLeaveOrg, useLeaveOrgTeam} from "../API/CampaignHooks";

interface Props {
    org: OrgModel;
    teams: OrgTeamModel[] | undefined;
    user: OrgUserModel | undefined;
    open: boolean;
    onClose: () => void;
}

const TeamsDialog = ({org, teams, user, open, onClose}: Props): ReactElement => {
    const classes = useStyles();

    const [myTeams, setMyTeams] = useState<OrgTeamModel[]>([]);
    const [leaveOrg] = useLeaveOrg(org);
    const [joinOrgTeam] = useJoinOrgTeam(org);
    const [leaveOrgTeam] = useLeaveOrgTeam(org);

    useEffect(()=>{
        if(teams){
            const mine = teams.map(t=>{
                let onTeam = false;
                if(user?.NetworkUser && user?.NetworkUser.teams){
                    onTeam = user?.NetworkUser.teams.filter(ut => ut.id === t.id).length > 0;
                }
                return {...t, selected: onTeam}
            });
            setMyTeams(mine);
        }else{
            setMyTeams([]);
        }
    }, [teams, user, setMyTeams])

    const toggleTeam = async (event: any, team: OrgTeamModel) => {
        console.log(team, event);
        const mine = myTeams.map(t =>{
            if(t.id===team.id){
                return {...t, selected: event.target.checked}
            }else{
                return t;
            }
        });
        setMyTeams(mine);
        if(event.target.checked){
            await joinOrgTeam(team);
            console.log("joined", team);
        }else{
            await leaveOrgTeam(team);
            console.log("left", team);
        }
        // onChange(false);
    }

    const unfollowOrg = async () => {
        const isSure = window.confirm(`Are you sure you want to unfollow "${org?.name}"? \nAll your badges will be deleted.`)
        if(isSure){
            console.log("yes leave " + org?.name);
            await leaveOrg();
            onClose();
        } else {
            console.log("no ");
        }
    }

    return (
        <Dialog onClose={onClose} open={open} className={classes.dialog}>
            <DialogTitle className={classes.title}>
                <div>Join a Team</div>
                <IconButton onClick={onClose} size="large">
                    <CancelIcon fontSize={"large"}/>
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.container}>
                {myTeams.map(team=>(
                    <div className={classes.item} key={team.id}>
                        <Switch
                            onChange={(selection) => toggleTeam(selection, team)}
                            checked={team.selected}
                        />
                        <div className={classes.itemName}>
                            {team.team_name}
                        </div>
                    </div>
                ))}
            </DialogContent>
            <DialogActions>
                <Button color={"inherit"}
                        className={classes.actionButton}
                        onClick={unfollowOrg}
                        startIcon={<VisibilityOffIcon/>}
                >
                    Leave
                </Button>
                <div style={{flex: 1}}></div>
                <Button variant={"contained"}
                        color={"primary"}
                        className={classes.actionButton}
                        onClick={onClose}
                        startIcon={<CheckIcon/>}
                        disabled={!true}
                >
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        dialog: {},
        container: {
            minWidth: 300,
            maxWidth: "100%",
            minHeight: 200,
            padding: "15px !important"
        },
        title: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 0 0 15px !important",
        },
        item:{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        itemName:{
            flex: 1,
        },
        actionButton:{
            marginTop: 10,
            marginBottom: 30,
            padding: 10
        },
    }
});

export default TeamsDialog;
