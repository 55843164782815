import React from "react";
import { CircularProgress, Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const Loading = () => {
    const classes = useStyles();

    return (
        <div className={classes.loadingWrapper}>
            <CircularProgress />
        </div>
    );
};
const useStyles = makeStyles((theme: Theme) => {
    return {
        loadingWrapper:{
            width: '100%',
            height: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }
});

export default Loading;
