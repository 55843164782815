import React, {ReactElement, useContext, useEffect} from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MainWrapper from "../Components/MainWrapper";
import {useJoinGroup, useMyGroups} from "../API/GroupHooks";
import Loading from "../Components/Loading";
import GroupsList from "../Components/GroupsList";
import {GroupContext} from "../utils/GroupContext";
import {useNavigate} from "react-router-dom";
import AuthWrapper from "../Components/AuthWrapper";

interface Props {
    c?: any;
}

const Groups = (props: Props): ReactElement => {
    const classes = useStyles();
    const navigate = useNavigate();
    const groupContext = useContext(GroupContext);
    const [joinGroup] = useJoinGroup();
    const {isLoading, data} = useMyGroups();
    useEffect(()=>{
        if(data && data.length===0){
            if(groupContext?.group.group_code){
                joinGroup(groupContext?.group.group_code).then(res=>{
                    navigate(`/App/${groupContext?.group.group_code}/Home`);
                })
            }
        }
        if (data && data.length===1){
            navigate(`/App/${data[0].group_code}/Home`);
        }
    },[data, navigate, groupContext, joinGroup])

    if (isLoading || !data) {return <Loading/>}

    return (
        <AuthWrapper>
            <MainWrapper>
                <div className={classes.container}>
                    <h3>My Groups</h3>
                    <GroupsList allowNew={false} allowRemove={false} />
                </div>
            </MainWrapper>
        </AuthWrapper>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {

        },
    }
});

export default Groups;
