import React, {ReactElement, useEffect, useState} from 'react';
import {Theme, Paper, TextField, IconButton, FormControlLabel, Switch} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {NavLink} from "react-router-dom";
import {useGetGroup, useJoinGroup, useLeaveGroup, useMyGroups, useUpdateGroup} from "../API/GroupHooks";
import Loading from "./Loading";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import {GroupModel, WrappedGroupModel} from "../API/Models";

interface Props {
    allowNew: boolean;
    allowRemove: boolean;
}

const GroupsList = ({allowNew, allowRemove}: Props): ReactElement => {
    const classes = useStyles();
    const [newGroup, setNewGroup] = useState('');
    const [newEnabled, setNewEnabled] = useState(false);
    const {isLoading, data} = useMyGroups();
    const getGroup = useGetGroup(newGroup);
    const [joinGroup] = useJoinGroup();
    const [leaveGroup] = useLeaveGroup();
    const [updateGroup] = useUpdateGroup();

    useEffect(() => {
        if (getGroup.data) {
            console.log(getGroup.data);
            setNewEnabled(true);
        } else {
            setNewEnabled(false);
        }
    }, [getGroup.data, setNewEnabled])

    if (isLoading || !data) {
        return <Loading/>
    }

    const handleNewGroupChange = (event: any) => {
        setNewGroup(event.target.value.toUpperCase());
    }

    const handleNewGroup = async () => {
        await joinGroup(newGroup);
        setNewGroup('');
    }

    const handleLeaveGroup = async (evt: any, group: GroupModel) => {
        evt.preventDefault();
        if(window.confirm(`Leave group ${group.name}?\nAre you Sure?`)) {
            await leaveGroup(group.group_code);
        }
    }

    const setDigest = async (g: WrappedGroupModel, enabled: boolean) =>{
        await updateGroup({id: g.id, group_code: g.group_code, digestEnabled: enabled});
    }

    return (
        <div className={classes.container}>
            <div className={classes.groupStrip}>
                {data.map((g, indx) => (
                    <div className={classes.groupButtonWrapper} key={`group-btn-${indx}`}>
                        <NavLink key={g.group_code}
                                 to={`/App/${g.group_code}/Home`}
                                 className={classes.groupButton}
                                 style={{backgroundImage: `linear-gradient(180deg, ${g.group.colors.primary}, ${g.group.colors.primary_shade})`}}
                        >
                            <img src={g.group.logo} className={classes.buttonImage} alt={g.group.name}/>
                            {allowRemove ?
                                <IconButton
                                    className={classes.removeButton}
                                    disabled={data?.length === 1}
                                    onClick={(e) => handleLeaveGroup(e, g.group)}
                                    size="large">
                                    <CancelIcon color={data?.length === 1 ? "disabled" : "action"}/>
                                </IconButton>
                                : null}
                        </NavLink>
                        {allowRemove ?
                        <div className={classes.row}>
                            <FormControlLabel
                                control={<Switch
                                    edge="start"
                                    onChange={(evt) => {
                                        setDigest(g, evt.target.checked)
                                    }}
                                    checked={g.digest_enabled}
                                />}
                                label="Receive Email Digest"
                            />
                        </div>
                        : null}
                    </div>
                ))}
                {data.length === 0 || allowNew ?
                    <Paper className={classes.paper}
                           style={{backgroundColor: "rgba(255,255,255,.5)", boxShadow: "0 0 1px rgba(0,0,0,.5)"}}
                           key={'newCampaign'}>
                        <div className={classes.newGroupButton}>
                            <TextField placeholder={"New Group Code"} value={newGroup} onChange={handleNewGroupChange}/>
                            <IconButton disabled={!newEnabled} onClick={handleNewGroup} size="large">
                                <AddCircleOutlineIcon fontSize={"large"} color={newEnabled ? "primary" : "disabled"}/>
                            </IconButton>
                        </div>
                    </Paper>
                    : null}
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {},
        groupStrip: {
            display: "flex",
            flexWrap: "wrap",
        },
        groupButtonWrapper:{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 15
        },
        groupButton: {
            width: 280,
            height: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: 5,
            borderRadius: 8,
            position: "relative"
        },
        buttonImage: {
            width: "70%",
            marginLeft: 15
        },
        removeButton: {
            position: "absolute",
            top: -25,
            right: -10,
            padding: 0,
            margin: 0,
            opacity: .5,
            backgroundColor: "white",
            border: "1px solid white",
            zIndex: 99,
            "&:hover": {
                backgroundColor: "white",
                opacity: 1,
            }
        },
        paper: {
            backgroundColor: "white",
            margin: 5,
            borderRadius: 8
        },
        newGroupButton: {
            width: 280,
            height: 100,
            boxSizing: "border-box",
            padding: "0 0 0 15px",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 8
        },
        row: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
    }
});

export default GroupsList;
