import React, {ReactElement} from 'react';
import { Theme, Paper, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {MessageModel} from "../API/Models";
import FuzzyDate from "./FuzzyDate";
import MessageIndicators from "./MessageIndicators";
import ReplyIcon from '@mui/icons-material/Reply';
import GroupsIcon from '@mui/icons-material/Groups';
import MessageAttachment from "./MessageAttachment";

interface Props {
    message: MessageModel;
    style?: any;
    action?: any;
}

const MessageCard = ({message, style, action}: Props): ReactElement => {
    const classes = useStyles();

    return (
        <Paper key={message.messageId} className={classes.message} style={style}>
            <div className={classes.messageHeader}>
                <img src={message.network.photos[0].path}
                     alt={message.network.name}
                     className={classes.messageHeaderLogo}/>
                <div className={classes.messageHeaderText}>
                    <p className={classes.campaignName}>
                        {message.network.name}
                        {message.team ?
                            <>
                                &nbsp;&bull;&nbsp;
                                {message.team.team_name}
                                <GroupsIcon style={{color: "#999", marginLeft: 5, height: 16}} fontSize={"small"}/>
                            </>
                            :null}
                    </p>
                    <div className={classes.messageSubHeader}>
                        <FuzzyDate date={message.publishedAt}/>
                        &nbsp;&bull;&nbsp;
                        <MessageIndicators message={message}/>
                    </div>
                </div>
                {message.shares > 0 ?
                    <Tooltip title={`You shared this message ${message.shares} times`}>
                        <div className={classes.messageShares}>
                            <div>{message.shares}</div>
                            <ReplyIcon/>
                        </div>
                    </Tooltip>
                    : null}
            </div>

            {message.instructions?
                <div className={classes.instructions}>
                    {message.instructions}
                </div>
                :null}

            <div className={classes.messageInnerBody}>
                {message.attachment ?
                    <MessageAttachment message={message}/>
                    :
                    <div className={classes.messageText}>
                        <h4>{message.title}</h4>
                        <p dangerouslySetInnerHTML={{__html: message.message.replace('\n', '<p/>')}}></p>
                    </div>
                }
            </div>

            {action}
        </Paper>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        message: {
            minHeight: 200,
            minWidth: 300,
            boxSizing: "border-box",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            marginBottom: 25,
            padding: 15,
            borderRadius: 4,
            textDecoration: "none",
            overflow: "hidden"
        },
        messageHeader: {
            display: "flex",
            alignItems: "flex-start",
        },
        messageHeaderText: {
            flex: 1
        },
        messageShares: {
            width: 100,
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "center",
            lineHeight: 1.4,
            fontSize: 16,
            color: "#616770"
        },
        messageHeaderLogo: {
            height: 32,
            width: 32,
            marginRight: 7,
        },
        campaignName: {
            margin: 0,
            fontSize: 14,
            color: "#616770",
            display: "flex",
            alignItems: "center"
        },
        instructions:{
            border: "1px solid #eee",
            borderRadius: 6,
            marginTop: 10,
            marginLeft: -8,
            marginRight: -8,
            padding: 8,
            fontSize: 14,
            color: "#616770",
        },
        messageSubHeader: {
            margin: 0,
            fontSize: 12,
            color: "#80858c",
            display: "flex",
            alignItems: "center"
        },
        messageBody: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textDecoration: "none",
            color: "#333"
        },
        messageInnerBody: {
            display: "flex",
            flexDirection: "row",
            flex: 1
        },
        messageText: {
            flex: 1,
            marginRight: 10,
            "& h4": {
                marginTop: 15
            }
        },
        shareLink:{
            textAlign: "right",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            padding: 10,
            margin: -10,
            "&:hover": {
                textDecoration: "underline"
            }
        },
        messageImageBox: {
            flex: 1,
            alignItems: "flex-end",
            paddingTop: 15,
        },
        messageImageBoxRight: {
            // flex: 1,
            // alignItems: "flex-end",
            // paddingTop: 15,
            float: "right",
            width: "40%",
            marginLeft: 15,
            marginTop: 15,
        },
        messageImage: {
            maxWidth: "100%"
        },
        preview: {
            overflow: "hidden",
            maxHeight: 76
        },
        full: {}
    }
});

export default MessageCard;
