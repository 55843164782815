import React from 'react';
import {format} from "date-fns";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import TextsmsIcon from "@mui/icons-material/Textsms";

type Props = {
    message: any;
    advocate: any;
    created_at: Date;
}

export default function Failed({message, advocate, created_at}: Props) {
    const classes = useStyles();

    if (created_at) {
        const timestamp = format(new Date(created_at), "h:mm a");
        const advocateByline = advocate ? ` by ${advocate.first_name} ${advocate.last_name} - {timestamp}` : timestamp;

        return (
            <div className={classes.container}>
                {/*<Text style={styles.details}>{message.title}</Text>*/}
                <p className={classes.details}>
                    {message.message_type === 'Email' ?
                        <EmailIcon style={{fontSize: 14, marginRight: 8}}/>
                        :
                        null}
                    {message.message_type === 'Text' ?
                        <TextsmsIcon style={{fontSize: 14, marginRight: 8}}/>
                        : null}
                    <strong>Failed&nbsp;</strong> - {advocateByline} - {message.title}
                </p>
            </div>
        );
    } else {
        return null;
    }
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            flex: 1,
            alignItems: 'flex-start',
        },
        details: {
            display: "flex",
            fontSize: 10,
            color: theme.palette.error.dark,
            margin: "0 10px 10px 0",
            alignItems: 'center',
            overflow: "hidden"
        }
    }
});
