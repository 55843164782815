import React, {ReactElement} from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useAdvocateMessages} from "../API/MessageHooks";
import {useParams} from "react-router-dom";

interface Props {
    c?: any;
}

const InboxCount = (props: Props): ReactElement => {
    const classes = useStyles();
    const {groupCode} = useParams();
    const {data} = useAdvocateMessages(false, groupCode);

    if(data && data.length>0) {
        return (
            <span className={classes.badge}>
                {data?.length}
            </span>
        );
    }else{
        return (<span></span>)
    }
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        badge: {
            display: "flex",
            width: 24,
            height: 24,
            margin: "0 10px",
            backgroundColor: "#c90000",
            color: "#fff",
            fontWeight: "bold",
            borderRadius: 12,
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center"
        }
    }
});

export default InboxCount;
