import React, {ReactElement, useContext} from 'react';
import { Breadcrumbs, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MainWrapper from "../Components/MainWrapper";
import {GroupContext} from "../utils/GroupContext";
import {NavLink, useParams} from "react-router-dom";
import {useGroupTheme} from "../API/useGroupTheme";
import {useGetMessage} from "../API/MessageHooks";
import MessageContacts from "../Components/MessageContacts";
import MessageCard from "../Components/MessageCard";
import MessageSocial from "../Components/MessageSocial";
import TwoColumnWrapper from "../Components/TwoColumnWrapper";
import Loading from "../Components/Loading";
import MessageConfig from "../Components/MessageConfig";
import AuthWrapper from "../Components/AuthWrapper";

interface Props {
    c?: any;
}

const Messages = (props: Props): ReactElement => {
    const classes = useStyles();
    const groupContext = useContext(GroupContext);
    const {groupCode, messageId} = useParams();
    useGroupTheme(groupCode);
    const group = groupContext?.group;
    const {data, isLoading} = useGetMessage(messageId || '');

    if (isLoading || !data) {return <Loading/>}

    // console.log(messageId, data)

    return (
        <AuthWrapper>
            <MainWrapper>
                <div>
                    {data ?
                        <div className={classes.container}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <NavLink className={classes.crumbLink}
                                         to={`/App/${group?.group_code}/Home`}>
                                    Home
                                </NavLink>
                                <NavLink className={classes.crumbLink}
                                         to={`/App/${group?.group_code}/Home/${data.network?data.network.networkId:''}`}>
                                    {data.network.name}
                                </NavLink>
                                <Typography color="textPrimary">{data.title}</Typography>
                            </Breadcrumbs>

                            <TwoColumnWrapper
                                sidebarStyle={{
                                    background: "none",
                                    boxShadow: "none",
                                    padding: 0,
                                }}
                                sidebar={(
                                    <div className={classes.sidebar}>
                                        {data.isPost ?
                                            <div>
                                                <img src={'/assets/sharing.svg'} alt={'Sharing Graphic'}
                                                     className={classes.sidebarGraphic}/>
                                            </div>
                                            :
                                            <MessageCard
                                                message={data}
                                                style={{
                                                    position: 'fixed',
                                                    marginRight: 20,
                                                }}
                                                action={<MessageConfig />}
                                            />
                                        }
                                    </div>
                                )}>
                                {data.isPost ?
                                    <MessageSocial message={data}/>
                                    :
                                    <MessageContacts message={data}/>
                                }
                            </TwoColumnWrapper>
                        </div>
                        : null}
                </div>
            </MainWrapper>
        </AuthWrapper>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            marginTop: 15
        },
        crumbLink: {
            color: "rgba(0,0,0,.54)",
            textDecoration: "none",
            "&:hover": {
                textDecoration: "underline"
            }
        },
        sidebar:{},
        sidebarGraphic: {
            width: "calc(100% - 20px)",
            margin: 10
        },
    }
});

export default Messages;
