import React, {ReactElement, useContext} from 'react';
import { Theme, Hidden } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {GroupContext} from "../utils/GroupContext";
import Cookie from "js-cookie";
import NotificationsIndicator from "./NotificationsIndicator";
import AppStoreButtons from "./AppStoreButtons";

interface Props {
    c?: any;
}

const Header = (props: Props): ReactElement => {
    const classes = useStyles();
    const groupContext = useContext(GroupContext);
    const userToken = Cookie.get("token");

    if(userToken) {
        return (
            <div className={classes.container}
                 style={{backgroundImage: `linear-gradient(0deg, ${groupContext?.group.colors.primary}, ${groupContext?.group.colors.primary_shade})`}}>
                <div className={classes.logoWrapper}>
                    <Hidden smUp>
                        <img className={classes.logo} src={groupContext?.group.logo} alt={groupContext?.group.name}/>
                    </Hidden>
                </div>
                <div className={classes.indicators}>
                    <NotificationsIndicator/>
                </div>
            </div>
        );
    }else if(groupContext?.group.group_code==='SWIPERED'){
        return (
            <div className={classes.container}>
                <Hidden smDown>
                    <div className={classes.logoWrapper}>
                    </div>
                </Hidden>
                <div style={{
                    flex: 1,
                    justifyContent:"center"
                }}>
                    <AppStoreButtons />
                </div>
            </div>
        );
    }else{
        return <div></div>;
    }
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: "flex",
            alignItems: "center",
            padding: 30,
            boxSizing: "border-box",
            height: 131,
            position:  "fixed",
            zIndex: 99,
            top: 0,
            left: 0,
            right: 0,
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
        },
        indicators:{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
        },
        logoWrapper:{
            flex: 1,
            marginLeft: 20,
            maxWidth: 150
        },
        logo:{
            maxWidth: 300,
            height: 60,
            margin: "auto",
        },
    }
});

export default Header;
