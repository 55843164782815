import React, {ReactElement, useState} from 'react';
import { Theme, FormControlLabel, Switch, IconButton, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MainWrapper from "../Components/MainWrapper";
import TwoColumnWrapper from "../Components/TwoColumnWrapper";
import {useAdvocateMessages, useMarkMessageRead} from "../API/MessageHooks";
import Loading from "../Components/Loading";
import MessageCard from "../Components/MessageCard";
import DoneIcon from '@mui/icons-material/Done';
import MailIcon from '@mui/icons-material/Mail';
import TextsmsIcon from '@mui/icons-material/Textsms';
import {OrgModel, MessageModel} from "../API/Models";
import ContactCampaignDetail from "../Components/ContactCampaignDetail";
import AuthWrapper from "../Components/AuthWrapper";
import {useParams} from "react-router-dom";

interface Props {
    c?: any;
}

const Inbox = (props: Props): ReactElement => {
    const classes = useStyles();
    const {groupCode} = useParams();
    const [showRead, setShowRead] = useState(false);
    const [contactWindowOpen, setContactWindowOpen] = useState(false);
    const {data, isLoading} = useAdvocateMessages(showRead, groupCode);
    const [markRead] = useMarkMessageRead();

    if (isLoading) {
        return <Loading/>
    }

    const handleShowRead = (event: any) => {
        setShowRead(event.target.checked);
    }

    const handleMarkRead = (message: MessageModel) => {
        markRead(message);
    }

    const handleMarkAllRead = () => {
        if(data) {
            data.map(async (m) => {
                markRead(m);
            });
        }
        setShowRead(false);
    }

    const handleContactCampaign = (contactType: string | null, campaign: OrgModel | null, message: MessageModel | null) => {
        if (campaign) {
            switch (contactType){
                case 'email':
                    window.location.href = `mailto:${campaign.volunteerEmail}?subject=${message ? encodeURI('RE: '+message.title) : ''}`;
                    break;
                case 'sms':
                    window.location.href = `sms:${campaign.volunteerPhone}`;
                    break;
            }
        } else {
            setContactWindowOpen(true);
        }
    }

    const handleContactWindowClose = () =>{
        setContactWindowOpen(false);
    }

    const actionsBar = (message: MessageModel) => (
        <div className={classes.actionBar}>
            {message.shares === 0 ?
                <Button
                    className={classes.button}
                    startIcon={<DoneIcon/>}
                    onClick={() => handleMarkRead(message)}
                >
                    Mark as Read
                </Button>
                : null}
            <div style={{flex: 1}}/>
            {message.network.volunteerEmail ?
                <IconButton
                    onClick={() => handleContactCampaign('email', message.network, message)}
                    size="large">
                    <MailIcon />
                </IconButton>
                : null}
            {message.network.volunteerPhone ?
                <IconButton
                    onClick={() => handleContactCampaign('sms', message.network, message)}
                    size="large">
                    <TextsmsIcon />
                </IconButton>
                : null}
        </div>
    );

    const sidebar = (
        <div className={classes.sidebar}>
            <div>
                <img src={'/assets/chat.svg'} alt={'Chat Graphic'} className={classes.sidebarGraphic}/>
            </div>
            <div className={classes.sidebarActions}>
                <div className={classes.button} onClick={()=>handleContactCampaign(null, null, null)}>Contact Campaign</div>
                <div className={classes.button} onClick={handleMarkAllRead}>Mark All as Read</div>
            </div>
            <p>
                These messages are internal communication for advocates only. You will see
                internal messages from all the campaigns you follow here.
            </p>
            <div className={classes.switchWrapper}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={showRead}
                            onChange={handleShowRead}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="Show Read Messages"
                />
            </div>
            <ContactCampaignDetail open={contactWindowOpen} onClose={handleContactWindowClose} />
        </div>
    );

    return (
        <AuthWrapper>
            <MainWrapper>
                <TwoColumnWrapper sidebar={sidebar}>
                    <div className={classes.container}>
                        {data ? data.map(msg => {
                            return (
                                <MessageCard
                                    key={msg.messageId}
                                    message={msg}
                                    action={actionsBar(msg)}
                                />
                            )
                        }) : null}
                    </div>
                </TwoColumnWrapper>
            </MainWrapper>
        </AuthWrapper>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            flex: 1,
        },
        sidebar: {
            flex: 1,
        },
        sidebarGraphic: {
            width: "calc(100% - 20px)",
            margin: 10
        },
        sidebarActions: {
            display: "flex",
            justifyContent: "space-between"
        },
        actionBar: {
            display: "flex",
        },
        button: {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            borderRadius: 25,
            display: "flex",
            alignItems: "center",
            padding: "8px 16px",
            cursor: "pointer",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 12,
            lineHeight: 1.5,
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
            }
        },
        switchWrapper: {
            textAlign: "center",
        }
    }
});

export default Inbox;
