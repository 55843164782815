import React, {ReactElement} from 'react';
import { format, isTomorrow, isThisYear } from 'date-fns'
import {Tooltip} from "@mui/material";

interface Props {
    date: Date;
    className?: string;
}

const FuzzyDate = ({date, className}: Props): ReactElement => {
    return (
        <Tooltip title={dateString(date)}><span className={className}>{transformDate(date)}</span></Tooltip>
    );
};

export default FuzzyDate;

export const dateString = (value: string | number | Date) => {
    const date = new Date(value);
    return `${date.toDateString()} ${date.toLocaleTimeString()}`
}

export const transformDate = (value: string | number | Date) => {
    if (value) {
        const date = new Date(value),
            diff = (((new Date()).getTime() - date.getTime()) / 1000),
            day_diff = Math.floor(diff / 86400);

        if (isNaN(day_diff)) {
            return;
        }

        //past
        if (day_diff >= 2 && day_diff < 7) {
            return '' + format(date, 'cccc'); //day of week
        }

        if (day_diff >= 7) {
            if(isThisYear(date)) {
                return '' + format(date, 'MMM do'); //short date format
            }else{
                return '' + format(date, 'MMM d, yyyy'); //short date format
            }
        }

        if (day_diff === 0){
            if( diff < 60 ) { return 'now'}
            else if( diff < 120 ) { return '1 min ago'}
            else if( diff < 3600 ) { return Math.floor(diff / 60) + ' mins ago'}
            else if( diff < 7200 ) { return '1 hr ago'}
            else if( diff < 86400 ) { return Math.floor(diff / 3600) + ' hrs ago'}
            else if( diff < 60 ) { return 'now'}
        }
        if (day_diff === 1) return 'Yesterday';

        //future
        if (day_diff <= -2 && day_diff > -7) {
            return '' + format(date, 'ccc h:mm a'); //day of week
        }

        if (day_diff <= -7) {
            if(isThisYear(date)) {
                return '' + format(date, 'MMM d, h:mm a'); //short date format
            }else{
                return '' + format(date, 'MMM d, yyyy, h:mm a'); //short date format
            }
        }

        if (day_diff === -1){
            if( diff > -60 ) { return 'now'}
            else if( diff > -120 ) { return 'in 1 min'}
            else if( diff > -3600 ) { return 'in '+Math.abs(Math.floor(diff / 60)) + ' mins'}
            else if( diff < -3600 ) {
                if(isTomorrow(date)){
                    return '' + format(date, 'ccc h:mm a'); //short date format
                } else {
                    return '' + format(date, 'h:mm a')
                }
            }
        }
    } else {
        return 'Never';
    }
}
