import React, {ReactElement, useContext} from 'react';
import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {MessageModel} from "../API/Models";
import LinkIcon from "@mui/icons-material/Link";
import {GroupContext} from "../utils/GroupContext";

type Props = {
    message: MessageModel;
    offset: number;
}

const AttachmentLink = ({message, offset}: Props): ReactElement => {
    const classes = useStyles();
    const groupContext = useContext(GroupContext);

    const getDomain = (url: string) => {
        if(url && url.length>5) {
            try {
                let domain = (new URL(url));
                return domain.hostname;
            }catch(e){
                console.error(e);
                return '';
            }
        }else{
            return '';
        }
    }

    const handleAttachmentUrl = async () => {
        if(message.attachment && message.attachment.url) {
            console.log(message.attachment.url);
            window.open(message.attachment.url);
        }
    }

    return (
        <div style={{marginTop: offset}}>
            <div className={classes.attachment} style={{
                backgroundColor: groupContext?.group.colors.track,
                borderColor: groupContext?.group.colors.track,
            }} onClick={handleAttachmentUrl}>
                {message.attachment && message.attachment.image ? (
                    <img alt={"attachment preview"} src={message.attachment.image} className={classes.attachmentImage}/>
                ) : null}
                {message.attachment && message.attachment.title ? (
                    <div className={classes.urlTitle} style={{color: groupContext?.group.colors.title}}>
                        {message.attachment.title}
                    </div>
                ): null}
                {message.attachment && message.attachment.url ? (
                    <div className={classes.urlDomain}>
                        {message.attachment && message.attachment.favicon && message.attachment.favicon.length>0 ?
                            <img alt={"favicon"} src={message.attachment.favicon} width={16} height={16}/>
                            :
                            <LinkIcon height={16} width={16} style={{color: groupContext?.group.colors.muted}} />
                        }
                        <div className={classes.urlDomainText} style={{color: groupContext?.group.colors.title}}>
                            {getDomain(message.attachment.url)}
                        </div>
                    </div>
                ): null}
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {},
        attachment:{
            marginTop: 15,
            borderWidth: 1,
            borderStyle: "solid",
            overflow: "hidden",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            borderBottomWidth: 0,
            textAlign: "center",
            cursor: "pointer"
        },
        attachmentImage:{
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            alignSelf: "center",
            width: "100%",
            height: "auto"
        },
        urlTitle: {
            flexWrap: "wrap",
            fontWeight: "500",
            fontSize: 14,
            marginTop: 8,
            marginBottom: 4,
            marginLeft: 15,
            marginRight: 15,
            textAlign: "left"
        },
        urlDomain:{
            display: 'flex',
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 8,
            marginLeft: 15,
            marginRight: 15,
            textAlign: "left"
        },
        urlDomainText: {
            flexWrap: "wrap",
            fontWeight: "700",
            fontSize: 12,
            marginLeft: 5,
            textAlign: "left"
        },
    }
});

export default AttachmentLink;
