import React, {ReactElement, useEffect, useState} from 'react';
import { Theme, Paper, Typography, Checkbox, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {ContactModel} from "../API/Models";
import Gravatar from "react-gravatar";
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import ClearIcon from '@mui/icons-material/Clear';
import PhoneDisplay from "./PhoneDisplay";

interface Props {
    contacts: ContactModel[];
    onEdit: (contact: ContactModel) => void;
    actionButton: any;
    onSelected: (selected: string[]) => void;
}

const ContactList = ({contacts, onEdit, onSelected, actionButton}: Props): ReactElement => {
    const classes = useStyles();
    const [selected, setSelected] = useState<string[]>([]);
    const [workingContacts, setWorkingContacts] = useState(contacts);

    useEffect(()=>{
        const mapped = contacts.map(c=>{
            c.selected=(selected.indexOf(c.id.toString())>=0);
            return c;
        });
        setWorkingContacts(mapped);
    }, [contacts, selected, setWorkingContacts])

    const handleRowSelect = (event: any) =>{
        // console.log(event.target.id);
        // console.log(event.target.checked);
        let sel = [...selected];
        if(event.target.checked){
            sel.push(event.target.id);
        }else{
            sel.splice(sel.indexOf(event.target.id),1);
        }
        setSelected(sel);
        onSelected(sel);
    }

    const handleClear = (event: any) =>{
        setSelected([]);
    }

    if (contacts.length > 0) {
        return (
            <Paper className={classes.container}>
                <div>
                    {selected.length>0 ?
                        <div className={classes.actionStrip}>
                            {actionButton}
                            <Button color={'secondary'}
                                    onClick={handleClear}
                            >
                                <ClearIcon/>
                            </Button>
                        </div>
                        : null
                    }
                    <div>
                        {workingContacts.map(c => (
                            <div className={classes.contact} key={`contact-${c.id}`}>
                                {c.email
                                    ? <Gravatar className={classes.avatar} email={c.email} default={'mm'} size={32}/>
                                    : <div className={classes.avatar}/>
                                }
                                <div className={classes.contactDetails}>
                                    <h4>{c.firstName} {c.lastName}</h4>
                                    <p>{c.email} <PhoneDisplay value={c.phone} /></p>
                                </div>
                                {c.source_id && c.source_id.length>0 ?
                                    <LinkIcon onClick={()=>onEdit(c)} />
                                    :
                                    <EditIcon onClick={()=>onEdit(c)} />
                                }
                                <Checkbox className={classes.checkbox} name={'selected'}
                                          checked={c.selected} id={c.id.toString()}
                                          onChange={handleRowSelect} />
                            </div>
                        ))}
                    </div>
                </div>
            </Paper>
        );
    } else {
        return (
            <Typography color={'textSecondary'} className={classes.empty}>
                No Contacts Found
            </Typography>
        )
    }

};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
        },
        contact: {
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            padding: "10px 15px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
            "&:last-child": {
                borderBottom: "none"
            },

        },
        contactDetails: {
            flex: 1,
            flexDirection: "column",
            "& h4": {
                margin: 0
            },
            "& p": {
                margin: 0,
                fontSize: 12,
                color: "#777",
            }
        },
        avatar: {
            width: 32,
            height: 32,
            borderRadius: 16,
            backgroundColor: "rgba(0, 0, 0, 0.08)",
            marginRight: 10
        },
        spacer: {
            width: 48,
            height: 48,
        },
        empty: {
            padding: theme.spacing(2),
        },
        checkbox:{
            marginLeft: 15
        },
        actionStrip:{
            display: "flex",
            justifyContent: "flex-end",
            padding: "15px 0",
            borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
        }
    }
});

export default ContactList;
