import axios from 'axios';
import Cookie from "js-cookie";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export function useClient() {
    return axios.create({});
}

export function useAuthClient() {
    const token = Cookie.get("token");
    if(token) {
        return axios.create({headers: {'Authorization': `Bearer ${token}`}});
    }else{
        return null;
    }
}
