import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import TextField from '@mui/material/TextField'

const InputPhone = (props: any) => {

    const lang = navigator.language;
    let country = 'US';
    if(lang.split('-').length>0){
        country = lang.split('-')[1].toUpperCase();
    }
    console.log(lang, country)

    return (
        <PhoneInput
            {...props}
            inputComponent={TextField}
            type='tel'
            smartCaret={false}
            defaultCountry={country}
            disabled={props.disabled}
            variant={props.variant}
        />
    );
};

export default InputPhone;
