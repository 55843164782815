import React, {ReactElement, useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import { Theme, TextField, InputAdornment, Button, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify';
import EmailIcon from "@mui/icons-material/Email";
import {GroupContext} from "../utils/GroupContext";

interface Props {
    c?: any;
}

const ForgotPasswordContainer = (props: Props): ReactElement => {
    const classes = useStyles();
    const groupContext = useContext(GroupContext);
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [username, setUsername] = useState('');
    const [busy, setBusy] = useState(false);

    const reset = async () => {
        try {
            await setBusy(true);
            Auth.configure({authenticationFlowType: 'USER_PASSWORD_AUTH'});
            await Auth.forgotPassword(username.toLowerCase());
            await setBusy(false);
            navigate('/Login/ResetPassword');
        } catch (err) {
            await setBusy(false);
            // @ts-ignore
            setError(err.message);
            console.log(err);
        }
    }

    return (
        <div className={classes.container}>
            <h2 className={classes.header} style={{color: groupContext?.group.colors.primary}}>
                Forgot My Password
            </h2>
            <TextField
                className={classes.textField}
                required
                name={"email"} id={"email"}
                label="Your Email Address"
                value={username}
                autoComplete={"username"}
                onChange={event => setUsername(event.target.value)}
                variant="outlined"
                disabled={busy}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <EmailIcon/>
                        </InputAdornment>
                    ),
                }}
            />
            <Button variant="contained" color="primary" onClick={reset} disabled={busy}>
                {!busy
                    ? <span>Reset Password</span>
                    : <CircularProgress color={"secondary"}/>
                }
            </Button>
            <div className={classes.error}>
                {error && error.length>0 ?
                    <p>{error}</p>
                    : null}
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            borderRadius: 10,
            width: "90%",
            maxWidth: 350,
            padding: 20,
            margin: "auto"
        },
        header: {
            marginTop: 0,
            marginBottom: 20
        },
        textField: {
            marginBottom: "10px !important"
        },
        error: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& p": {
                paddingTop: 10,
                color: "#cb2629"
            }
        }
    }
});

export default ForgotPasswordContainer;
