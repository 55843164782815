import React, {ReactElement, useContext, useState} from 'react';
import { Theme, Paper, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Gravatar from 'react-gravatar';
import {ContactModel, MessageModel} from "../API/Models";
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import {useMarkMessageShared, useMarkMessageSkipped} from "../API/MessageHooks";
import {prepMessage} from "../utils/StringUtils";
import {GroupContext} from "../utils/GroupContext";
import {useMyProfile} from "../API/ProfileHooks";
import ContactActionsSendDialog from "./ContactActionsSendDialog";
import {useSnackbar} from "notistack";

interface Props {
    contacts: ContactModel[];
    message: MessageModel;
}

const ContactActionsList = ({contacts, message}: Props): ReactElement => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [markShared] = useMarkMessageShared();
    const [markSkipped] = useMarkMessageSkipped();
    const [busy, setBusy] = useState(false);
    const profile = useMyProfile();
    const ua = navigator.userAgent.toLowerCase();
    const groupContext = useContext(GroupContext);
    const [activeContact, setActiveContact] = useState<ContactModel | null>(null);

    const handleAction = async (type: string, contact: ContactModel) => {
        setBusy(true);
        let body = message.message;
        if(contact.isP2P && message.isP2P){
            body = message.p2p_message;
        }
        if(groupContext && profile.data) {
            body = prepMessage(body, groupContext?.group.group_code, contact, profile.data);
        }
        switch (type) {
            case "email":
                await markShared({message: message, contact: contact, type: type, p2p: contact.isP2P});
                window.location.href = `mailto:${contact.email}?subject=${message.title}&body=${body}`;
                break;
            case "call":
                await markShared({message: message, contact: contact, type: type, p2p: contact.isP2P});
                window.location.href = `tel:${contact.phone}`;
                break;
            case "sms":
                if(contact.isP2P){
                    setActiveContact(contact);
                } else {
                    await markShared({message: message, contact: contact, type: type, p2p: contact.isP2P});
                    if (ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1 || ua.indexOf("macintosh") > -1) {
                        window.location.href = `sms:${contact.phone}&body=${encodeURIComponent(body)}`;
                    } else {
                        window.location.href = `sms:${contact.phone}?body=${encodeURIComponent(body)}`;
                    }
                }
                break;
            case "facebook":
                await markShared({message: message, contact: contact, type: type, p2p: contact.isP2P});
                window.location.href = `https://m.me/${contact.facebook}?body=${body}`;
                break;
            case "skip":
                await markSkipped({message: message, contact: contact, p2p: contact.isP2P});
                enqueueSnackbar(`Peer ${contact.firstName} ${contact.lastName} skipped`, {variant: 'success'});
                break;
        }
        setBusy(false);
    }

    const handleClose = async (success: boolean) => {
        if(success && activeContact){
            await markShared({message: message, contact: activeContact, type: 'sms', p2p: true});
        }
        setActiveContact(null);
    }

    if (contacts.length > 0) {
        return (
            <Paper className={classes.container}>
                {contacts.map(c => (
                    <div className={classes.contact} key={c.id}>
                        {c.email
                            ? <Gravatar className={classes.avatar} email={c.email} default={'mm'} size={32}/>
                            : <div className={classes.avatar}/>
                        }
                        <h4>{c.firstName} {c.lastName}</h4>
                        {c.isP2P ?
                        <IconButton
                            disabled={busy}
                            aria-label="skip"
                            onClick={() => handleAction('skip', c)}
                            size="large">
                            <PlaylistRemoveIcon />
                        </IconButton>
                            :null}
                        {c.facebook ?
                            <IconButton
                                disabled={busy}
                                aria-label="facebook"
                                onClick={() => handleAction('facebook', c)}
                                size="large">
                                <FacebookIcon />
                            </IconButton>
                            : null
                        }
                        {c.email ?
                            <IconButton
                                disabled={busy}
                                aria-label="email"
                                onClick={() => handleAction('email', c)} size="large">
                                <EmailIcon/>
                            </IconButton>
                            : <div className={classes.spacer}></div>
                        }
                        {c.phone ?
                            <IconButton
                                disabled={busy}
                                aria-label="call"
                                onClick={() => handleAction('call', c)} size="large">
                                <PhoneIcon/>
                            </IconButton>
                            : <div className={classes.spacer}></div>
                        }
                        {c.phone ?
                            <IconButton
                                disabled={busy}
                                aria-label="sms"
                                onClick={() => handleAction('sms', c)} size="large">
                                <SmsIcon/>
                            </IconButton>
                            : <div className={classes.spacer}></div>
                        }
                    </div>
                ))}
                <ContactActionsSendDialog contact={activeContact} message={message} onClose={handleClose} />
            </Paper>
        );
    } else {
        return (
            <Typography color={'textSecondary'} className={classes.empty}>
                No Contacts Found
            </Typography>
        )
    }
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            padding: 15
        },
        contact: {
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
            "&:last-child": {
                borderBottom: "none"
            },
            "& h4": {
                flex: 1,
            }
        },
        avatar: {
            width: 32,
            height: 32,
            borderRadius: 16,
            backgroundColor: "rgba(0, 0, 0, 0.08)",
            marginRight: 10
        },
        spacer: {
            width: 48,
            height: 48,
        },
        empty: {
            padding: theme.spacing(2),
        }
    }
});

export default ContactActionsList;
