import React, {ReactElement} from 'react';
import { Button, Paper, SvgIcon, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {MessageModel} from "../API/Models";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import MessageCard from "./MessageCard";
import copy from 'copy-to-clipboard';
import {useMarkMessageShared} from "../API/MessageHooks";

interface Props {
    message: MessageModel;
}

const MessageSocial = ({message}: Props): ReactElement => {
    // const findLinkRegEx = /(\b(((https?|ftp|file|):\/\/)|www[.])[-A-Z0-9+&@#%?=~_|!:,.;]*[-A-Z0-9+&@#%=~_|])/ig;
    const classes = useStyles();
    const [markShared] = useMarkMessageShared();

    const handleShare = async (network: string) => {
        switch (network) {
            case "facebook":
                await markShared({message: message, type: network, contact: undefined, p2p: false});
                if(message.attachment && message.attachment.url && message.attachment.url.length>0) {
                    copy(message.message);
                    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(message.attachment.url)}&quote=${encodeURI(message.message)}`)
                }else if(message.attachment && message.attachment.image && message.attachment.image.length>0){
                    copy(message.message);
                    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(message.attachment.image)}&quote=${encodeURI(message.message)}`)
                }else {
                    copy(message.message);
                    window.open(`https://www.facebook.com/`)
                }
                break;
            case "twitter":
                await markShared({message: message, type: network, contact: undefined, p2p: false});
                window.open(`https://twitter.com/share?text=${encodeURI(message.message)}&url=${encodeURI(message.attachment.url || message.attachment.image)}`)
                break;
            case "instagram":
                break;
            case "snapchat":
                break;
        }
    }

    return (
        <div className={classes.container}>
            <Paper className={classes.paper}>
                <MessageCard message={message} />
                <div className={classes.socialWrapper}>
                    {message.isFacebook ?
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{backgroundColor: "#4267b2", color: "#FFF"}}
                            className={classes.button}
                            startIcon={<FacebookIcon/>}
                            onClick={() => handleShare('facebook')}
                        >
                            Share to Facebook
                        </Button>
                        : null}
                    {message.isTwitter ?
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{backgroundColor: "#00bef3", color: "#FFF"}}
                            className={classes.button}
                            startIcon={<TwitterIcon/>}
                            onClick={() => handleShare('twitter')}
                        >
                            Share to Twitter
                        </Button>
                        : null}
                    {message.isInstagram ?
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{backgroundColor: "#e467ba", color: "#000"}}
                            className={classes.button}
                            startIcon={<InstagramIcon/>}
                            onClick={() => handleShare('instagram')}
                        >
                            Share to Instagram
                        </Button>
                        : null}
                    {message.isSnapchat ?
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{backgroundColor: "#ffea00", color: "#000"}}
                            className={classes.button}
                            onClick={() => handleShare('snapchat')}
                            startIcon={
                                <SvgIcon viewBox={"0 0 512 512"}>
                                    <path xmlns="http://www.w3.org/2000/svg"
                                          d="M510.846 392.673c-5.211 12.157-27.239 21.089-67.36 27.318-2.064 2.786-3.775 14.686-6.507 23.956-1.625 5.566-5.623 8.869-12.128 8.869l-.297-.005c-9.395 0-19.203-4.323-38.852-4.323-26.521 0-35.662 6.043-56.254 20.588-21.832 15.438-42.771 28.764-74.027 27.399-31.646 2.334-58.025-16.908-72.871-27.404-20.714-14.643-29.828-20.582-56.241-20.582-18.864 0-30.736 4.72-38.852 4.72-8.073 0-11.213-4.922-12.422-9.04-2.703-9.189-4.404-21.263-6.523-24.13-20.679-3.209-67.31-11.344-68.498-32.15a10.627 10.627 0 0 1 8.877-11.069c69.583-11.455 100.924-82.901 102.227-85.934.074-.176.155-.344.237-.515 3.713-7.537 4.544-13.849 2.463-18.753-5.05-11.896-26.872-16.164-36.053-19.796-23.715-9.366-27.015-20.128-25.612-27.504 2.437-12.836 21.725-20.735 33.002-15.453 8.919 4.181 16.843 6.297 23.547 6.297 5.022 0 8.212-1.204 9.96-2.171-2.043-35.936-7.101-87.29 5.687-115.969C158.122 21.304 229.705 15.42 250.826 15.42c.944 0 9.141-.089 10.11-.089 52.148 0 102.254 26.78 126.723 81.643 12.777 28.65 7.749 79.792 5.695 116.009 1.582.872 4.357 1.942 8.599 2.139 6.397-.286 13.815-2.389 22.069-6.257 6.085-2.846 14.406-2.461 20.48.058l.029.01c9.476 3.385 15.439 10.215 15.589 17.87.184 9.747-8.522 18.165-25.878 25.018-2.118.835-4.694 1.655-7.434 2.525-9.797 3.106-24.6 7.805-28.616 17.271-2.079 4.904-1.256 11.211 2.46 18.748.087.168.166.342.239.515 1.301 3.03 32.615 74.46 102.23 85.934 6.427 1.058 11.163 7.877 7.725 15.859z"/>
                                </SvgIcon>
                            }
                        >
                            Share to Snapchat
                        </Button>
                        : null}
                </div>
                {message.isFacebook ?
                    <div className={classes.facebookWarning}>
                        <strong>Note:</strong> Facebook now blocks direct message sharing so you will have
                        to <strong>paste</strong> the text into the create post box.
                    </div>
                    : null}
            </Paper>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            flex: 1,
        },
        paper: {
            flex: 1,
            padding: 20,
            marginBottom: 20,
            "& h3": {
                marginTop: 0,
            }
        },
        socialWrapper: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center"
        },
        button: {
            margin: "10px !important",
            minWidth: 250,
        },
        facebookWarning: {
            fontSize: 12,
            margin: "20px auto",
            maxWidth: 320,
        }
    }
});

export default MessageSocial;
