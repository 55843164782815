import React, {ReactElement, useContext} from 'react';
import { Theme, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {GroupContext} from "../utils/GroupContext";

interface Props {
    c?: any;
}

const AppStoreButtons = (props: Props): ReactElement => {
    const classes = useStyles();
    const groupContext = useContext(GroupContext);

    if(groupContext?.group.group_code==='SWIPERED'){
        return (
            <div className={classes.container}>
                <Link href={`https://apps.apple.com/us/app/swipered/id1496473241`} target={'_blank'}>
                    <img src={'/assets/apple-appstore-badge.png'} className={classes.buttonBadge}
                         alt={'Download on the AppStore'}/>
                </Link>
                <Link href={`https://play.google.com/store/apps/details?id=com.swipered`} target={'_blank'}>
                    <img src={'/assets/google-play-badge.png'} className={classes.buttonBadge}
                         alt={'Download on the PlayStore'}/>
                </Link>
            </div>
        );
    }else {
        return (
            <div className={classes.container}>
                <Link href={`https://apps.apple.com/us/app/id1528807464`} target={'_blank'}>
                    <img src={'/assets/apple-appstore-badge.png'} className={classes.buttonBadge}
                         alt={'Download on the AppStore'}/>
                </Link>
                <Link href={`https://play.google.com/store/apps/details?id=com.teamupwith`} target={'_blank'}>
                    <img src={'/assets/google-play-badge.png'} className={classes.buttonBadge}
                         alt={'Download on the PlayStore'}/>
                </Link>
            </div>
        );
    }
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: "flex",
            marginTop: 20,
            alignItems: "center",
            justifyContent: "center"
        },
        buttonBadge:{
            width: 80,
            margin: 5
        }
    }
});

export default AppStoreButtons;
