import React, {ReactElement} from 'react';
import { parsePhoneNumber, formatPhoneNumber } from 'react-phone-number-input'

interface Props {
    value: string;
}

const PhoneDisplay = ({value}: Props): ReactElement => {
    if(value && value.length>0) {
        let phoneNumber = parsePhoneNumber(value);
        if(value.substr(0,1)!=='+'){
            phoneNumber = parsePhoneNumber('+1'+value);
        }
        if(phoneNumber) {
            return (<span>{formatPhoneNumber(phoneNumber.number)}</span>)
        }else{
            return (<span>{value}</span>);
        }
    }else{
        return (<span></span>);
    }
};

export default PhoneDisplay;
