import React, {ReactElement, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useGroupTheme} from "../API/useGroupTheme";
import Cookie from "js-cookie";

interface Props {
    children: React.ReactElement;
}

const AuthWrapper = ({children}: Props): ReactElement => {
    const navigate = useNavigate();
    const [userToken, setUserToken] = useState(Cookie.get("token"));
    const {groupCode} = useParams();
    useGroupTheme(groupCode);

    useEffect(() => {
        if (!userToken || userToken.length===0) {
            const token = Cookie.get("token");
            if(token) {
                setUserToken(token);
            }
        }
    },[userToken])

    if (!userToken) {
        console.log('not logged in', window.location.pathname);
        if (window.location.pathname !== '/') {
            console.log('setting cookie');
            Cookie.set('returnPath', window.location.pathname);
            navigate('/');
            return <></>;
        } else {
            return children;
        }
    } else {
        const returnPath = Cookie.get('returnPath');
        if (returnPath) {
            console.log('handling redirect', returnPath);
            Cookie.remove('returnPath');
            navigate(returnPath);
            return <></>;
        } else {
            return children;
        }
    }
};

export default AuthWrapper;
