import React, {ReactElement, useContext, useState} from 'react';
import { Theme, TextField, InputAdornment, Button, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useNavigate} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {GroupContext} from "../utils/GroupContext";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import VpnKeyIcon from '@mui/icons-material/VpnKey';

interface Props {
    email?: string;
    onChange?: () => void;
}

const ResetPasswordContainer = ({email, onChange}: Props): ReactElement => {
    const classes = useStyles();
    const groupContext = useContext(GroupContext);
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [code, setCode] = useState('');
    const [username, setUsername] = useState(email || '');
    const [password, setPassword] = useState('');
    const [busy, setBusy] = useState(false);

    const changePassword = async () => {
        try {
            await setBusy(true);
            await Auth.forgotPasswordSubmit(username, code, password);
            await setBusy(false);
            if(onChange){
                onChange();
            }else {
                navigate('/Login');
            }
        } catch (err) {
            await setBusy(false);
            // @ts-ignore
            setError(err.message);
            console.log(err);
        }
    }

    return (
        <div className={classes.container}>
            <h2 className={classes.header} style={{color: groupContext?.group.colors.primary}}>
                New Password
            </h2>
            <TextField
                className={classes.textField}
                required
                id="outlined-otp"
                label="Password Reset Confirmation Code"
                value={code}
                onChange={event => setCode(event.target.value)}
                variant="outlined"
                disabled={busy}
                autoComplete={'one-time-code'}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <VpnKeyIcon/>
                        </InputAdornment>
                    ),
                }}
            />
            {!email ?
                <TextField
                    className={classes.textField}
                    required
                    label="Email"
                    value={username}
                    onChange={event => setUsername(event.target.value)}
                    variant="outlined"
                    disabled={busy}
                    autoComplete={"username"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon/>
                            </InputAdornment>
                        ),
                    }}
                />
                : null}
            <TextField
                className={classes.textField}
                label="New Password"
                type="password"
                value={password}
                onChange={event => setPassword(event.target.value)}
                variant="outlined"
                disabled={busy}
                autoComplete={"new-password"}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <LockIcon/>
                        </InputAdornment>
                    ),
                }}
            />
            <Button variant="contained" color="primary" onClick={changePassword} disabled={busy}>
                {!busy
                    ? <span>Change Password</span>
                    : <CircularProgress color={"secondary"}/>
                }
            </Button>
            <div className={classes.error}>
                {error && error.length>0 ?
                    <p>{error}</p>
                    : null}
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            borderRadius: 10,
            width: "90%",
            maxWidth: 350,
            padding: 20,
            margin: "auto"
        },
        header: {
            marginTop: 0,
            marginBottom: 20
        },
        textField: {
            marginBottom: 10,
        },
        error: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& p": {
                paddingTop: 10,
                color: "#cb2629"
            }
        }
    }
});

export default ResetPasswordContainer;
