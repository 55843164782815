import React, {ReactElement} from 'react';
import { Link, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

interface Props {
    c?: any;
}
const DNF = (props: Props): ReactElement => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.mainImage}></div>
            <div className={classes.mainText}>
                <h1>Page Not Found</h1>
                <p>We can't find the page you're looking for</p>
                <div className={classes.actions}>
                    <Link href={'/Home'} className={classes.button}>Return Home</Link>
                    <Link href={'mailto:support@buzz360.co'} className={classes.button}>Contact Us</Link>
                </div>
            </div>
        </div>
    );
};
const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            margin: "200px auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: 520
        },
        mainImage: {
            width: 212,
            height: 171,
            marginTop: 20,
            marginRight: 20,
            backgroundImage: "url(/assets/SadBrowser.png)",
            backgroundSize: "contain"
        },
        mainText:{
            flex: 1,
            "& h1":{
                textAlign: "center",
            },
            "& p":{
                fontSize: 12,
                textAlign: "center",
            }
        },
        actions:{
            display: "flex",
            justifyContent: "center"
        },
        button: {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            borderRadius: 25,
            margin: 10,
            display: "flex",
            alignItems: "center",
            padding: "8px 16px",
            cursor: "pointer",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 12,
            lineHeight: 1.5,
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
            }
        },
    }
});
export default DNF;
