import React from 'react';
import {format} from "date-fns";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import TextsmsIcon from '@mui/icons-material/Textsms';

type Props = {
    message: any;
    details: any;
    created_at: Date;
}

export default function Unsubscribed({message, details, created_at}: Props) {
    const classes = useStyles();

    if(created_at) {
        const timestamp = format(new Date(created_at), "h:mm a");

        return (
            <div className={classes.container}>
                {/*<p style={styles.details}>{message.title}</p>*/}
                <p className={classes.details}>
                    {message.message_type==='Email'?
                        <EmailIcon style={{fontSize:14, marginRight: 8}} />
                        :null
                    }
                    {message.message_type==='Text'?
                        <TextsmsIcon style={{fontSize:14, marginRight: 8}} />
                        :null
                    }
                    {timestamp} - Unsubscribed - {details.message} - {message.title}
                </p>
            </div>
        );
    }else{
        return null;
    }
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            flex: 1,
            alignItems: 'flex-end',
            justifyContent: 'center',
        },
        details: {
            fontSize: 14,
            color: theme.palette.primary.dark,
            marginRight: 15,
            display: "flex",
            alignItems: "center"
        }
    }
});
