import {queryCache, useMutation, useQuery} from "react-query";
import {ContactModel, MessageChoiceCounts, MessageModel} from "./Models";
import {useAuthClient} from "./useClient";

export function useOrgMessages(org_id: string) {
    const client = useAuthClient();

    return useQuery<MessageModel[]>(['orgMessages'], async () => {
        try {
            if (org_id && client) {
                const response = await client.get(`/messages/org/${org_id}`);
                return response.data;
            } else {
                return [];
            }
        } catch (err) {
            console.error(err);
        }
    });
}

export function useGroupMessages(group_code: string) {
    const client = useAuthClient();

    return useQuery<MessageModel[]>(['groupMessages', group_code], async () => {
        try {
            if (group_code && client) {
                const response = await client.get(`/messages?group_code=${group_code}`);
                return response.data;
            } else {
                return [];
            }
        } catch (err) {
            console.error(err);
        }
    });
}

export function useAdvocateMessages(includeRead: boolean, groupCode?: string) {
    const client = useAuthClient();

    return useQuery<MessageModel[]>(['advocateMessages', groupCode, includeRead], async () => {
        try {
            if(client) {
                if(groupCode) {
                    const response = await client.get(`/messages/advocates/group/${groupCode}`);
                    if (includeRead) {
                        return response.data;
                    } else {
                        return response.data.filter((m: MessageModel) => m.shares === 0);
                    }
                }else{
                    console.log('useAdvocateMessages', 'missing groupCode')
                    return [];
                }
            } else {
                console.log('useAdvocateMessages', 'missing client')
                return [];
            }
        } catch (err) {
            console.error(err);
        }
    });
}

export function useGetMessage(message_id: string) {
    const client = useAuthClient();

    return useQuery<MessageModel>(['orgMessage', message_id], async () => {
        try {
            if (message_id && client) {
                const response = await client.get(`/messages/message/${message_id}`);
                return response.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error(err);
        }
    });
}

export function useMarkMessageAnswered() {
    const client = useAuthClient();

    interface answeredInput {
        message: MessageModel,
        choice: number
    }

    const markAnswered = async (answer: answeredInput) => {
        try {
            if (answer.message && client) {
                const response = await client.post(`/messages/advocates/${answer.message.messageId}`, {
                    message: answer.message,
                    action: "mark",
                    share_type: "answered",
                    choice: answer.choice
                });
                await queryCache.invalidateQueries('advocateMessages');
                await queryCache.invalidateQueries('orgMessages');
                await queryCache.invalidateQueries('groupMessages');
                return response.data.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error('MessageHooks.useMarkMessageAnswered', err);
        }
    }

    return useMutation(markAnswered);
}

export function useGetMessageChoice(message_id: string, choice: number) {
    const client = useAuthClient();

    return useQuery<MessageChoiceCounts>(['messageChoice', message_id, choice], async () => {
        try {
            if (message_id && client) {
                const response = await client.get(`/messages/message/${message_id}/choices/${choice}`);
                return response.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error('MessageHooks.useGetMessageChoice', err);
        }
    });
}

export function useMarkMessageRead() {
    const client = useAuthClient();

    const markRead = async (message: MessageModel) => {
        try {
            if (message && client) {
                const response = await client.post(`/messages/advocates/${message.messageId}` ,{
                    message: message,
                    action: "mark",
                    share_type: "read",
                });
                await queryCache.invalidateQueries('advocateMessages');
                return response.data.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error(err);
        }
    }

    return useMutation(markRead);
}

export function useMarkMessageShared() {
    const client = useAuthClient();

    interface sharedInput {
        message: MessageModel,
        contact?: ContactModel,
        type: string,
        p2p: boolean
    }

    const markShared = async (input: sharedInput) => {
        try {
            if (input.message && client) {
                const data = {
                    share_type: input.type,
                    message: input.message.messageId,
                    advocate_contact_id: input.contact ? input.contact.id : null,
                    contact_id: input.contact ? input.contact.matchId : null,
                    p2p: input.p2p,
                    org_id: input.message.network.networkId
                };
                if(input.p2p && input.contact) {
                    data.advocate_contact_id = null;
                    data.contact_id = input.contact.id.toString();
                    data.p2p = true;
                }
                const response = await client.post(`/messages/message/${input.message.messageId}/shared`, data);
                await queryCache.invalidateQueries(['messageContacts', input.message.messageId]);
                await queryCache.invalidateQueries(['messageP2PContacts', input.message.messageId]);
                await queryCache.invalidateQueries(['contacts_search']); // because of the counts
                await queryCache.invalidateQueries(['groupMessages']); // because of the counts
                return response.data.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error('MessageHooks.useMarkMessageShared', err);
        }
    }

    return useMutation(markShared);
}

export function useMarkMessageSkipped() {
    const client = useAuthClient();

    interface sharedInput {
        message: MessageModel,
        contact?: ContactModel,
        p2p: boolean
    }

    const markSkipped = async (input: sharedInput) => {
        try {
            if (input.message && client) {
                const data = {
                    share_type: 'skipped',
                    message: input.message.messageId,
                    advocate_contact_id: input.contact ? input.contact.id : null,
                    contact_id: input.contact ? input.contact.matchId : null,
                    p2p: input.p2p,
                    org_id: input.message.network.networkId
                };
                if(input.p2p && input.contact) {
                    data.advocate_contact_id = null;
                    data.contact_id = input.contact.id.toString();
                    data.p2p = true;
                }
                const response = await client.post(`/messages/message/${input.message.messageId}/skipped`, data);
                await queryCache.invalidateQueries(['messageContacts', input.message.messageId]);
                await queryCache.invalidateQueries(['messageP2PContacts', input.message.messageId]);
                await queryCache.invalidateQueries(['contacts_search']); // because of the counts
                await queryCache.invalidateQueries(['groupMessages']); // because of the counts
                return response.data.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error('MessageHooks.useMarkMessageSkipped', err);
        }
    }

    return useMutation(markSkipped);
}
