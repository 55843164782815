import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {AdvocateContactActionsModel, ContactModel} from "../../API/Models";
import {useGetContactActions} from "../../API/ActionHooks";
import OutgoingMessage from "./OutgoingMessage";
import Clicked from "./Clicked";
import IncomingMessage from "./IncomingMessage";
import Failed from "./Failed";
import Skipped from "./Skipped";
import Unsubscribed from "./Unsubscribed";
import ActionStatus from "./ActionStatus";
import Loading from "../Loading";
import {transformDate} from "../FuzzyDate";

interface Props {
    contact: ContactModel;
}

const ContactHistory = ({contact}: Props): ReactElement => {
    const classes = useStyles();
    const [history, setHistory] = useState<AdvocateContactActionsModel[]>([]);
    const contactActions = useGetContactActions(contact);

    useEffect(() => {
        if (contactActions.data && contactActions.data) {
            const c = [...contactActions.data]
            setHistory(c.reverse());
        } else {
            setHistory([]);
        }
    }, [contactActions.data]);

    const AlwaysScrollToBottom = () => {
        const elementRef = useRef<null | HTMLDivElement>(null);

        useEffect(() => {
            // @ts-ignore
            elementRef.current.scrollIntoView()
        });
        return <div ref={elementRef}/>;
    };

    if (contactActions.isLoading) {
        return <Loading/>
    }

    let lastSection = '';

    return (
        <div className={classes.list}>
            {history.map(ma => {
                const newSection = transformDate(ma.created_at)!;
                let header = (lastSection !== newSection) ?
                    <div key={`header-${ma.id}`} className={classes.header}>{newSection}</div> : null;
                switch (ma.action) {
                    case 'share':
                        lastSection = newSection;
                        return [
                            header,
                            <OutgoingMessage
                                key={`om-${ma.id}`}
                                details={ma.details}
                                message={ma.message}
                                contact={contact}
                                advocate={ma.advocate}
                                created_at={ma.created_at}/>
                        ];
                    case 'replied':
                        lastSection = newSection;
                        return [
                            header,
                            <IncomingMessage
                                key={`im-${ma.id}`}
                                details={ma.details}
                                advocate={ma.advocate}
                                created_at={ma.created_at}/>
                        ];
                    case 'skipped':
                        lastSection = newSection;
                        return [
                            header,
                            <Skipped
                                key={`sk-${ma.id}`}
                                message={ma.message}
                                advocate={ma.advocate}
                                created_at={ma.created_at}/>
                        ];
                    case 'clicked':
                        lastSection = newSection;
                        return [
                            header,
                            <Clicked
                                key={`cl-${ma.id}`}
                                message={ma.message}
                                details={ma.details}
                                created_at={ma.created_at}/>
                        ]
                    case 'unsubscribed':
                        lastSection = newSection;
                        return [
                            header,
                            <Unsubscribed
                                key={`un-${ma.id}`}
                                message={ma.message}
                                details={ma.details}
                                created_at={ma.created_at}/>
                        ];
                    case 'failed':
                        lastSection = newSection;
                        return [
                            header,
                            <Failed
                                key={`fail-${ma.id}`}
                                message={ma.message}
                                advocate={ma.advocate}
                                created_at={ma.created_at}/>
                        ];
                    case 'delivered':
                    case 'queued':
                    case 'sent':
                    case 'opened':
                        lastSection = newSection;
                        return null;
                    default:
                        lastSection = newSection;
                        return [
                            header,
                            <ActionStatus
                                key={`as-${ma.id}`}
                                message={ma.message}
                                advocate={ma.advocate}
                                status={ma.action}
                                created_at={ma.created_at}/>
                        ];
                }
            })}
            <div style={{flex: 1}} />
            <AlwaysScrollToBottom/>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        list: {
            padding: 10,
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            width: 640
        },
        header: {
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: 10
        }
    }
});

export default ContactHistory;
