import {queryCache, useMutation, useQuery} from "react-query";
import {ContactModel, ContactsAssignmentsModel, MessageModel} from "./Models";
import {useAuthClient} from "./useClient";
import {AdvocateContactModel} from "../utils/googleModels";

export function useMessageContacts(message_id: string) {
    const client = useAuthClient();

    return useQuery<ContactModel[]>(['messageContacts', message_id], async () => {
        try {
            if (message_id && client) {
                const response = await client.get(`/messages/message/${message_id}/contact`);
                return response.data;
            } else {
                return [];
            }
        } catch (err) {
            console.error(err);
        }
    });
}

export function useMessageP2PContacts(message_id: string) {
    const client = useAuthClient();

    return useQuery<ContactsAssignmentsModel>(['messageP2PContacts', message_id], async () => {
        try {
            if (message_id && client) {
                const response = await client.get(`/messages/message/${message_id}/p2pContactAssigned`);
                return response.data;
            } else {
                return {
                    contacts: [],
                    completed: 0
                };
            }
        } catch (err) {
            console.error(err);
        }
    });
}


export function useAssignP2PContacts(message: MessageModel | undefined) {
    const client = useAuthClient();

    const assignContacts = async () => {
        try {
            if (message && client) {
                const limit = 5;
                const response = await client.get(`/messages/message/${message.messageId}/p2pAssign/${limit}`);
                await queryCache.invalidateQueries(['messageP2PContacts']);
                return response.data;
            } else {
                return [];
            }
        } catch (err) {
            console.error('ContactHooks.useAssignP2PContacts', err);
        }
    };

    return useMutation(assignContacts);
}


export function useMyContacts() {
    const client = useAuthClient();

    return useQuery<ContactModel[]>(['contacts'], async () => {
        try {
            if(client){
                const response = await client.get(`/contacts`);
                return response.data;
            } else {
                return [];
            }
        } catch (err) {
            console.error(err);
        }
    });
}

export function useMyDeletedContacts() {
    const client = useAuthClient();

    return useQuery<ContactModel[]>(['deleted_contacts'], async () => {
        try {
            if(client) {
                const response = await client.get(`/contacts/deleted`);
                return response.data;
            } else {
                return [];
            }
        } catch (err) {
            console.error(err);
        }
    });
}

export function useUploadContacts() {
    const client = useAuthClient();

    const contactsUpload = async (contacts: AdvocateContactModel[]) => {
        try {
            if (contacts && client) {
                const response = await client.post(`/contacts`, contacts);
                await queryCache.invalidateQueries(['contacts']);
                return response.data.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error(err);
        }
    }

    return useMutation(contactsUpload);
}

export function useMarkDeleted() {
    const client = useAuthClient();

    interface deletionType {
        ids: string[],
        deleted: boolean,
    }

    const markDeleted = async (deleteData: deletionType) => {
        try {
            if (deleteData && client) {
                const response = await client.post(`/contacts/markDeleted`, deleteData);
                await queryCache.invalidateQueries(['contacts']);
                await queryCache.invalidateQueries(['deleted_contacts']);
                return response.data.data;
            } else {
                return null;
            }
        } catch (err) {
            console.error(err);
        }
    }

    return useMutation(markDeleted);
}
